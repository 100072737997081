<template>
  <section class="schita-dentara h100 relative">
    <nav v-show="isMenu" ref="menu" class="fixed">
      <i
        class="absolute pointer"
        @click="isMenu = false"
        v-html="svg.close"
      ></i>
      <ul data-test="schita-dentara-menu">
        <li
          class="pointer"
          @click="setAction(item)"
          v-for="(item, index) in meniu"
          :key="index"
        >
          <span>{{ item }}</span>
        </li>
      </ul>
    </nav>

    <pacient-header />

    <main-modal />

    <p class="hover fixed" ref="hover" v-show="hoverText">{{ hoverText }}</p>

    <figure
      class="oglinda-dentara flex-center h65"
      :class="{ crosshair: isPunte }"
      type="dinti-box"
      @click="clickDinte"
    >
      <i id="schita-icon" class="w100">
        <span class="codes code-top block flex flex-between">
          <span v-for="(item, index) in coduri.top" :key="index">
            {{ item }}
          </span>
        </span>
        <svg
          width="968px"
          id="svg-schita"
          height="454px"
          viewBox="0 0 968 354"
          type="dinti-box"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g ref="punte-top" class="dinti-bottom">
              <g id="molar-3.8" transform="translate(891.399343, 197.659520)">
                <g>3.8</g>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M56.9006566,43.9404799 C57.3006566,33.5404799 55.2006566,37.2404799 47.1006566,38.3404799 C24.1006566,41.3404799 0.300656582,13.6404799 10.8006566,61.8404799 C13.6006566,74.7404799 18.9006566,87.1404799 27.2006566,97.5404799 C32.2006566,103.94048 52.8006566,120.34048 42.4006566,99.3404799 C39.3006566,92.9404799 37.2006566,87.0404799 35.5006566,80.1404799 C34.9006566,77.5404799 32.9006566,62.6404799 38.6006566,71.1404799 C46.6006566,83.2404799 47.9006566,90.9404799 60.7006566,101.04048 C63.4006566,103.14048 75.1006566,108.44048 74.3006566,102.04048 C74.1006566,100.14048 66.1006566,90.9404799 64.2006566,87.4404799 C57.0006566,74.1404799 56.3006566,58.6404799 56.9006566,43.9404799 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M8.50065658,1.54047987 C1.80065658,3.74047987 1.50065658,12.1404799 2.80065658,17.8404799 C4.90065658,26.7404799 4.40065658,30.7404799 13.6006566,30.6404799 C24.6006566,30.5404799 62.4006566,49.5404799 61.0006566,16.9404799 C60.5006566,4.84047987 56.9006566,7.34047987 48.6006566,5.04047987 C34.8006566,1.24047987 40.2006566,3.84047987 28.5006566,4.64047987 C22.8006566,4.94047987 14.7006566,-0.459520135 8.50065658,1.54047987 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M56.9006566,43.9404799 C56.3006566,58.6404799 57.0006566,74.1404799 64.2006566,87.3404799 C66.1006566,90.8404799 74.0006566,99.9404799 74.3006566,101.94048 C75.0006566,108.34048 63.4006566,103.14048 60.7006566,100.94048 C47.9006566,90.8404799 46.6006566,83.1404799 38.6006566,71.0404799 C33.0006566,62.5404799 34.9006566,77.4404799 35.5006566,80.0404799 C37.1006566,86.9404799 39.2006566,92.8404799 42.4006566,99.2404799 C52.8006566,120.24048 32.3006566,103.84048 27.2006566,97.4404799 C19.0006566,87.0404799 13.7006566,74.6404799 10.8006566,61.7404799 C0.200656582,13.6404799 24.0006566,41.2404799 47.1006566,38.2404799 C55.1006566,37.2404799 57.3006566,33.5404799 56.9006566,43.9404799 L56.9006566,43.9404799 Z M8.50065658,1.54047987 C1.80065658,3.74047987 1.50065658,12.1404799 2.80065658,17.8404799 C4.90065658,26.7404799 4.40065658,30.7404799 13.6006566,30.6404799 C24.6006566,30.5404799 62.4006566,49.5404799 61.0006566,16.9404799 C60.5006566,4.84047987 56.9006566,7.34047987 48.6006566,5.04047987 C34.8006566,1.24047987 40.2006566,3.84047987 28.5006566,4.64047987 C22.8006566,4.94047987 14.7006566,-0.459520135 8.50065658,1.54047987 L8.50065658,1.54047987 Z M47.2006566,106.04048 C46.7006566,101.54048 40.6006566,89.9404799 38.9006566,83.7404799 C30.1006566,52.8404799 53.9006566,116.34048 73.7006566,106.04048 C80.6006566,102.44048 71.2006566,93.5404799 68.7006566,89.9404799 C59.0006566,76.1404799 57.8006566,53.9404799 59.8006566,37.7404799 C62.4006566,17.3404799 69.3006566,3.14047987 41.3006566,1.34047987 C36.8006566,1.04047987 32.4006566,1.44047987 27.9006566,1.24047987 C22.6006566,1.04047987 -0.0993434177,-5.55952013 0.000327209757,13.8404799 C0.000327209757,17.5404799 4.90065658,33.5404799 5.70065658,39.8404799 C8.60065658,62.8404799 12.6006566,89.9404799 33.3006566,106.34048 C36.8006566,109.24048 48.2006566,116.44048 47.2006566,106.04048 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-38"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="molar-3.7" transform="translate(813.202089, 193.842315)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M55.4979107,36.3576852 C49.1979107,40.9576852 44.9979107,42.6576852 37.0979107,41.4576852 C28.4979107,40.1576852 22.9979107,40.8576852 15.3979107,40.0576852 C4.89791074,38.8576852 3.99791074,34.3576852 11.1979107,70.5576852 C13.4979107,82.4576852 17.4979107,101.157685 23.0979107,111.657685 C24.5979107,114.457685 31.4979107,124.657685 35.3979107,120.757685 C39.9979107,116.157685 26.5979107,91.0576852 29.7979107,80.9576852 C32.1979107,73.3576852 38.4979107,86.1576852 39.5979107,88.1576852 C42.4979107,93.7576852 54.3979107,118.957685 60.5979107,119.257685 C68.5979107,119.657685 55.8979107,87.6576852 55.1979107,83.4576852 C51.9979107,65.6576852 56.9979107,52.3576852 55.4979107,36.3576852 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M9.09791074,1.55768522 C-1.30208926,5.85768522 -0.502089262,32.4576852 10.5979107,36.9576852 C18.7979107,40.2576852 24.0979107,35.6576852 31.8979107,37.6576852 C39.4979107,39.6576852 45.3979107,41.9576852 52.5979107,36.2576852 C62.4979107,28.3576852 61.4979107,4.05768522 46.9979107,3.55768522 C37.8979107,3.25768522 34.0979107,10.8576852 26.9979107,7.95768522 C21.9979107,5.95768522 15.5979107,-1.04231478 9.09791074,1.55768522 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M55.4979107,36.3576852 C57.0979107,52.3576852 51.9979107,65.6576852 55.0979107,83.3576852 C55.7979107,87.4576852 68.5979107,119.557685 60.4979107,119.157685 C54.2979107,118.857685 42.4979107,93.7576852 39.4979107,88.0576852 C38.4979107,86.0576852 32.0979107,73.2576852 29.6979107,80.8576852 C26.4979107,90.9576852 39.8979107,116.057685 35.2979107,120.657685 C31.3979107,124.557685 24.4979107,114.357685 22.9979107,111.557685 C17.3979107,101.057685 13.3979107,82.3576852 11.0979107,70.4576852 C3.89791074,34.2576852 4.79791074,38.7576852 15.2979107,39.9576852 C22.8979107,40.7576852 28.3979107,40.0576852 36.9979107,41.3576852 C44.9979107,42.6576852 49.1979107,40.9576852 55.4979107,36.3576852 L55.4979107,36.3576852 Z M9.09791074,1.55768522 C-1.30208926,5.85768522 -0.502089262,32.4576852 10.5979107,36.9576852 C18.7979107,40.2576852 24.0979107,35.6576852 31.8979107,37.6576852 C39.4979107,39.6576852 45.3979107,41.9576852 52.5979107,36.2576852 C62.4979107,28.3576852 61.4979107,4.05768522 46.9979107,3.55768522 C37.8979107,3.25768522 34.0979107,10.8576852 26.9979107,7.95768522 C21.9979107,5.95768522 15.5979107,-1.04231478 9.09791074,1.55768522 L9.09791074,1.55768522 Z M0.397910738,25.7576852 C1.49791074,33.7576852 4.29791074,44.1576852 5.69791074,52.8576852 C7.19791074,61.8576852 8.79791074,70.8576852 10.7979107,79.7576852 C13.2979107,90.8576852 19.3979107,119.657685 31.7979107,123.357685 C47.4979107,128.057685 28.6979107,89.5576852 32.2979107,83.5576852 C34.5979107,79.7576852 46.8979107,110.157685 54.5979107,118.057685 C62.3979107,125.957685 66.7979107,118.957685 65.2979107,110.157685 C63.9979107,102.257685 59.5979107,94.1576852 57.8979107,85.8576852 C53.3979107,64.9576852 58.7979107,47.5576852 60.3979107,27.2576852 C63.0979107,-9.24231478 36.7979107,5.45768522 29.3979107,4.85768522 C21.6979107,4.25768522 15.9979107,-3.04231478 7.39791074,1.45768522 C-0.202089262,5.25768522 -0.602089262,18.4576852 0.397910738,25.7576852 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-37"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="molar-3.6" transform="translate(737.285489, 193.657275)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M9.2145107,42.9427252 C11.3145107,60.8427252 7.8145107,76.0427252 9.9145107,92.7427252 C11.1145107,102.242725 17.8145107,125.242725 27.8145107,128.942725 C34.8145107,131.442725 30.9145107,117.042725 30.1145107,114.642725 C20.8145107,87.2427252 31.9145107,76.7427252 39.0145107,88.7427252 C40.2145107,90.8427252 65.1145107,145.942725 71.2145107,130.642725 C72.3145107,127.942725 63.4145107,94.0427252 62.6145107,86.8427252 C59.4145107,57.4427252 67.5145107,46.0427252 54.0145107,46.2427252 C44.3145107,46.3427252 40.0145107,44.3427252 31.3145107,43.3427252 C24.5145107,42.5427252 16.4145107,43.4427252 9.2145107,42.9427252 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M9.2145107,1.94272523 C-3.2854893,7.34272523 2.4145107,38.0427252 14.1145107,40.6427252 C24.0145107,42.8427252 26.4145107,38.5427252 39.0145107,42.6427252 C44.9145107,44.5427252 58.9145107,45.8427252 62.9145107,40.1427252 C66.6145107,34.8427252 67.9145107,21.4427252 66.8145107,15.1427252 C65.1145107,5.44272523 58.3145107,10.4427252 49.5145107,5.34272523 C38.9145107,-0.757274774 36.9145107,4.14272523 28.8145107,6.64272523 C23.1145107,8.34272523 18.4145107,-1.95727477 9.2145107,1.94272523 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M9.2145107,42.9427252 C16.4145107,43.4427252 24.4145107,42.5427252 31.3145107,43.3427252 C40.0145107,44.3427252 44.4145107,46.2427252 54.0145107,46.2427252 C67.6145107,46.1427252 59.4145107,57.4427252 62.6145107,86.8427252 C63.4145107,94.0427252 72.3145107,127.942725 71.2145107,130.642725 C65.1145107,145.942725 40.2145107,90.8427252 39.0145107,88.7427252 C31.9145107,76.6427252 20.8145107,87.2427252 30.1145107,114.642725 C30.9145107,117.042725 34.8145107,131.542725 27.8145107,128.942725 C17.9145107,125.342725 11.1145107,102.242725 9.9145107,92.7427252 C7.9145107,76.0427252 11.4145107,60.8427252 9.2145107,42.9427252 L9.2145107,42.9427252 Z M9.2145107,1.94272523 C-3.2854893,7.34272523 2.4145107,38.0427252 14.1145107,40.6427252 C24.0145107,42.8427252 26.4145107,38.5427252 39.0145107,42.6427252 C44.9145107,44.5427252 58.9145107,45.8427252 62.9145107,40.1427252 C66.6145107,34.8427252 67.9145107,21.4427252 66.8145107,15.1427252 C65.1145107,5.44272523 58.3145107,10.4427252 49.5145107,5.34272523 C38.9145107,-0.757274774 36.9145107,4.14272523 28.8145107,6.64272523 C23.1145107,8.34272523 18.4145107,-1.95727477 9.2145107,1.94272523 L9.2145107,1.94272523 Z M7.6145107,48.9427252 C8.8145107,59.8427252 7.2145107,70.1427252 7.2145107,80.9427252 C7.2145107,94.1427252 12.6145107,125.542725 27.2145107,130.642725 C44.0145107,136.442725 20.0145107,87.5427252 33.4145107,87.6427252 C39.2145107,87.7427252 46.4145107,114.842725 59.1145107,128.642725 C64.3145107,134.242725 73.8145107,139.842725 73.6145107,127.442725 C73.5145107,120.442725 68.5145107,107.742725 66.9145107,99.8427252 C56.1145107,46.1427252 79.3145107,17.5427252 62.3145107,7.64272523 C56.9145107,4.44272523 43.7145107,0.742725226 37.6145107,1.34272523 C16.2145107,3.64272523 22.2145107,-0.457274774 12.9145107,0.0427252263 C2.6145107,0.642725226 -0.685489301,12.1427252 0.114510699,20.9427252 C0.814510699,28.2427252 6.5145107,39.3427252 7.6145107,48.9427252 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-36"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g
                id="premolar-3.5"
                transform="translate(683.088283, 195.611636)"
              >
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M13.4117172,50.8883643 L11.9117172,49.8883643 L10.5117172,49.3883643 L9.31171718,48.6883643 L8.31171718,47.6883643 L7.71171718,46.3883643 C6.21171718,46.0883643 6.51171718,46.4883643 7.01171718,45.0883643 L5.71171718,44.8883643 L5.81171718,46.3883643 L7.11171718,50.6883643 C8.01171718,67.0883643 8.11171718,85.3883643 10.7117172,101.388364 C11.7117172,107.488364 18.2117172,139.588364 26.1117172,138.288364 C29.9117172,134.688364 28.3117172,121.388364 28.4117172,115.988364 C28.5117172,107.588364 29.3117172,98.8883643 30.5117172,90.5883643 C31.8117172,81.1883643 37.3117172,53.1883643 36.2117172,45.4883643 C33.8117172,44.3883643 29.2117172,58.0883643 13.4117172,50.8883643 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M18.2117172,1.78836429 C16.0117172,2.48836429 6.11171718,8.88836429 4.61171718,10.5883643 C-3.08828282,19.9883643 6.31171718,54.6883643 24.4117172,50.3883643 C34.9117172,47.8883643 39.8117172,31.2883643 39.7117172,21.8883643 C39.6117172,14.4883643 38.1117172,11.6883643 32.2117172,7.68836429 C28.6117172,5.28836429 22.7117172,0.388364288 18.2117172,1.78836429 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M7.11171718,50.5883643 L5.81171718,46.2883643 L5.71171718,44.7883643 C5.81171718,44.4883643 6.01171718,44.0883643 6.11171718,43.9883643 C6.21171718,43.9883643 6.51171718,44.1883643 6.51171718,44.0883643 C6.61171718,43.6883643 6.81171718,44.6883643 7.01171718,44.9883643 L7.71171718,46.2883643 L8.31171718,47.5883643 L9.31171718,48.5883643 L10.5117172,49.2883643 L11.9117172,49.7883643 C13.8117172,49.4883643 13.4117172,49.0883643 13.4117172,50.7883643 C29.1117172,57.9883643 33.8117172,44.2883643 36.1117172,45.3883643 C37.2117172,53.0883643 31.8117172,80.9883643 30.4117172,90.4883643 C29.2117172,98.7883643 28.5117172,107.488364 28.3117172,115.888364 C28.2117172,121.188364 29.8117172,134.588364 26.0117172,138.188364 C18.1117172,139.388364 11.6117172,107.288364 10.6117172,101.288364 C8.11171718,85.2883643 8.01171718,66.9883643 7.11171718,50.5883643 L7.11171718,50.5883643 Z M18.2117172,1.78836429 C16.0117172,2.48836429 6.11171718,8.88836429 4.61171718,10.5883643 C-3.08828282,19.9883643 6.31171718,54.6883643 24.4117172,50.3883643 C34.9117172,47.8883643 39.8117172,31.2883643 39.7117172,21.8883643 C39.6117172,14.4883643 38.1117172,11.6883643 32.2117172,7.68836429 C28.6117172,5.28836429 22.7117172,0.388364288 18.2117172,1.78836429 L18.2117172,1.78836429 Z M0.311717179,25.9883643 C1.91171718,40.2883643 5.21171718,51.3883643 5.91171718,66.8883643 C6.51171718,81.3883643 6.81171718,94.5883643 9.91171718,108.988364 C11.4117172,115.788364 17.2117172,138.688364 24.3117172,140.988364 C33.1117172,143.788364 30.6117172,110.788364 31.1117172,105.088364 L41.7117172,22.9883643 C41.9117172,9.98836429 24.3117172,-11.7116357 4.71171718,7.78836429 C-0.288282821,12.5883643 -0.388282821,19.3883643 0.311717179,25.9883643 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <polygon
                  fill="#989189"
                  points="10.7117172 49.3883643 13.7117172 50.9883643 14.5117172 49.6883643 11.1117172 48.6883643"
                ></polygon>
                <g
                  type="implant"
                  id="implant-premolar-35"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g
                id="premolar-3.4"
                transform="translate(631.534403, 194.698382)"
              >
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M6.46559682,48.201618 C7.26559682,60.001618 16.2655968,137.801618 29.8655968,137.401618 C35.2655968,133.101618 30.6655968,101.101618 30.5655968,92.801618 C30.4655968,86.401618 33.1655968,47.801618 32.4655968,46.001618 C29.1655968,44.901618 25.6655968,53.901618 11.4655968,47.801618 L10.2655968,46.901618 L9.06559682,46.101618 L8.06559682,45.101618 L7.36559682,43.801618 C5.96559682,43.501618 6.16559682,44.001618 6.66559682,42.501618 L5.46559682,42.601618 L5.56559682,43.801618 L6.46559682,48.201618 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M18.0655968,2.00161802 C15.1655968,3.00161802 9.26559682,8.80161802 6.86559682,11.201618 C1.86559682,16.101618 1.36559682,19.201618 2.56559682,26.101618 C8.26559682,59.901618 38.1655968,51.201618 37.6655968,21.901618 C37.4655968,11.001618 20.4655968,1.10161802 18.0655968,2.00161802 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M5.56559682,43.901618 L5.46559682,42.701618 C5.56559682,42.401618 6.16559682,42.201618 6.26559682,41.801618 C6.36559682,41.401618 6.56559682,42.301618 6.66559682,42.601618 L7.36559682,43.901618 L8.06559682,45.201618 L9.06559682,46.201618 L10.2655968,47.001618 L11.4655968,47.901618 C25.6655968,54.001618 29.1655968,45.001618 32.4655968,46.101618 C33.1655968,47.801618 30.4655968,86.501618 30.5655968,92.901618 C30.6655968,101.101618 35.2655968,133.201618 29.8655968,137.501618 C16.2655968,137.901618 7.26559682,60.101618 6.46559682,48.301618 L5.56559682,43.901618 L5.56559682,43.901618 Z M18.0655968,2.00161802 C15.1655968,3.00161802 9.26559682,8.80161802 6.86559682,11.201618 C1.86559682,16.101618 1.36559682,19.201618 2.56559682,26.101618 C8.26559682,59.901618 38.1655968,51.201618 37.6655968,21.901618 C37.4655968,11.001618 20.4655968,1.10161802 18.0655968,2.00161802 L18.0655968,2.00161802 Z M0.0655968224,24.901618 C0.465596822,30.001618 2.96559682,37.501618 3.56559682,43.601618 C4.36559682,50.501618 4.96559682,57.501618 5.86559682,64.401618 C7.46559682,77.601618 9.76559682,90.801618 12.6655968,103.701618 C14.4655968,111.801618 18.9655968,132.301618 24.8655968,137.701618 C38.6655968,150.401618 33.6655968,112.901618 33.4655968,108.901618 C32.4655968,94.601618 32.6655968,79.301618 33.8655968,65.001618 C34.9655968,51.701618 39.2655968,28.101618 39.4655968,24.701618 C40.3655968,9.00161802 23.0655968,0.101618024 19.3655968,0.00080242113 C14.4655968,-0.0983819758 -1.13440318,9.00161802 0.0655968224,24.901618 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-premolar-34"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="canin-3.3" transform="translate(574.033349, 192.396809)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M27.0666514,61.2031912 C16.1666514,66.4031912 8.06665141,52.4031912 7.56665141,57.2031912 C6.16665141,73.4031912 11.9666514,133.603191 23.0666514,152.203191 C24.1666514,154.103191 28.7666514,160.603191 31.4666514,158.903191 C33.6666514,157.503191 31.9666514,148.203191 31.6666514,145.703191 C29.3666514,121.703191 30.9666514,98.5031912 33.1666514,74.5031912 C35.7666514,48.5031912 34.2666514,57.7031912 27.0666514,61.2031912 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M16.8666514,1.90319123 C-10.6333486,12.8031912 6.06665141,66.5031912 25.3666514,59.7031912 C35.5666514,56.1031912 39.8666514,35.9031912 39.4666514,26.5031912 C38.9666514,17.9031912 25.7666514,-1.69680877 16.8666514,1.90319123 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M7.56665141,57.2031912 C7.96665141,52.4031912 16.1666514,66.4031912 27.0666514,61.2031912 C34.3666514,57.7031912 35.7666514,48.5031912 33.3666514,74.5031912 C31.0666514,98.4031912 29.5666514,121.603191 31.8666514,145.703191 C32.0666514,148.303191 33.8666514,157.603191 31.6666514,158.903191 C28.9666514,160.603191 24.3666514,154.103191 23.2666514,152.203191 C11.9666514,133.603191 6.16665141,73.4031912 7.56665141,57.2031912 L7.56665141,57.2031912 Z M16.8666514,1.90319123 C-10.6333486,12.8031912 6.06665141,66.5031912 25.3666514,59.7031912 C35.5666514,56.1031912 39.8666514,35.9031912 39.4666514,26.5031912 C38.9666514,17.9031912 25.7666514,-1.69680877 16.8666514,1.90319123 L16.8666514,1.90319123 Z M0.166651406,31.2031912 C0.766651406,37.2031912 4.06665141,45.1031912 4.96665141,52.3031912 C5.96665141,60.2031912 6.06665141,68.7031912 6.56665141,76.7031912 C7.56665141,92.4031912 8.76665141,108.203191 11.6666514,123.603191 C19.2666514,164.103191 36.5666514,171.103191 34.4666514,149.303191 C31.7666514,121.703191 33.6666514,77.8031912 37.9666514,51.0031912 C39.1666514,43.4031912 41.5666514,36.2031912 41.3666514,28.4031912 C41.1666514,18.3031912 31.1666514,-0.19680877 19.2666514,0.00158278304 C5.16665141,0.10319123 -1.13334859,19.4031912 0.166651406,31.2031912 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-canin-33"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="incisiv-3.2" transform="translate(528.093626, 188.219585)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M6.20637425,49.3804146 C6.20637425,59.8804146 6.40637425,143.080415 16.2063742,143.780415 C18.3063742,140.780415 19.1063742,104.180415 19.6063742,97.0804146 C20.2063742,89.9804146 24.5063742,56.1804146 23.0063742,52.0804146 C20.0063742,51.2804146 19.2063742,55.8804146 14.2063742,55.4804146 C10.5063742,55.1804146 8.70637425,51.7804146 6.20637425,49.3804146 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M4.20637425,3.28041455 C-0.693625751,12.8804146 5.40637425,70.9804146 22.2063742,47.5804146 C26.7063742,41.3804146 27.5063742,23.1804146 27.7063742,15.2804146 C27.8063742,9.88041455 28.5063742,3.78041455 21.8063742,2.78041455 C18.5063742,2.48041455 7.50637425,2.68041455 4.20637425,3.28041455 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M6.20637425,49.3804146 C8.80637425,51.7804146 10.6063742,55.1804146 14.2063742,55.4804146 C19.1063742,55.8804146 20.0063742,51.2804146 23.0063742,52.0804146 C24.5063742,56.1804146 20.2063742,89.9804146 19.6063742,97.0804146 C19.0063742,104.180415 18.3063742,140.780415 16.2063742,143.780415 C6.30637425,143.080415 6.10637425,59.8804146 6.20637425,49.3804146 L6.20637425,49.3804146 Z M4.20637425,3.28041455 C-0.693625751,12.8804146 5.40637425,70.9804146 22.2063742,47.5804146 C26.7063742,41.3804146 27.5063742,23.1804146 27.7063742,15.2804146 C27.8063742,9.88041455 28.5063742,3.78041455 21.8063742,2.78041455 C18.5063742,2.48041455 7.50637425,2.68041455 4.20637425,3.28041455 L4.20637425,3.28041455 Z M29.6063742,23.3804146 C31.0063742,5.48041455 29.3063742,-1.81958545 9.10637425,0.380414553 C-4.19362575,1.88041455 0.506374249,25.5804146 2.20637425,34.9804146 C6.70637425,59.7804146 3.80637425,91.6804146 6.80637425,118.380415 C7.00637425,120.080415 11.7063742,156.080415 18.3063742,144.480415 C20.4063742,140.780415 20.9063742,110.380415 21.4063742,103.480415 C23.1063742,76.4804146 27.5063742,49.7804146 29.6063742,23.3804146 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-incisiv-32"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="incisiv-3.1" transform="translate(488.359004, 187.487152)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M16.240996,132.712848 C19.440996,130.012848 19.240996,97.9128483 19.740996,91.2128483 C20.140996,84.9128483 23.440996,54.8128483 22.040996,51.2128483 C19.340996,50.5128483 17.640996,53.0128483 13.040996,52.0128483 C9.74099597,51.3128483 8.34099597,48.9128483 6.44099597,49.2128483 C3.84099597,56.5128483 7.74099597,130.612848 16.240996,132.712848 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M4.14099597,3.31284826 C-0.659004033,6.61284826 3.44099597,42.6128483 9.84099597,48.0128483 C14.140996,51.6128483 19.540996,50.6128483 22.140996,45.6128483 C25.040996,40.0128483 27.040996,20.8128483 27.440996,14.0128483 C27.740996,8.31284826 28.440996,3.51284826 21.540996,2.61284826 C19.040996,2.31284826 5.84099597,2.11284826 4.14099597,3.31284826 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M16.240996,132.712848 C7.74099597,130.712848 3.84099597,56.6128483 6.34099597,49.2128483 C8.34099597,48.9128483 9.64099597,51.3128483 12.940996,52.0128483 C17.640996,52.9128483 19.240996,50.5128483 21.940996,51.2128483 C23.340996,54.8128483 20.040996,84.9128483 19.640996,91.2128483 C19.240996,97.8128483 19.540996,129.912848 16.240996,132.712848 L16.240996,132.712848 Z M4.14099597,3.31284826 C-0.659004033,6.61284826 3.44099597,42.6128483 9.84099597,48.0128483 C14.140996,51.6128483 19.540996,50.6128483 22.140996,45.6128483 C25.040996,40.0128483 27.040996,20.8128483 27.440996,14.0128483 C27.740996,8.31284826 28.440996,3.51284826 21.540996,2.61284826 C19.040996,2.31284826 5.84099597,2.11284826 4.14099597,3.31284826 L4.14099597,3.31284826 Z M3.74099597,55.1128483 C4.04099597,71.6128483 4.94099597,116.512848 11.340996,130.412848 C14.140996,136.512848 18.840996,136.412848 20.240996,129.612848 C21.240996,124.712848 20.840996,115.512848 20.940996,110.112848 C21.740996,86.1128483 23.540996,57.3128483 27.340996,33.6128483 C28.640996,25.4128483 33.940996,3.61284826 23.040996,0.812848261 C19.240996,-0.187151739 7.44099597,-0.487151739 3.94099597,1.21284826 C-1.05900403,3.71284826 0.0409959671,12.3128483 0.240995967,17.0128483 C0.740995967,30.2128483 3.44099597,41.1128483 3.74099597,55.1128483 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-incisiv-31"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="incisiv-4.1" transform="translate(450.122914, 187.387916)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M6.57708573,49.2120845 C7.47708573,63.2120845 8.97708573,77.2120845 9.87708573,91.3120845 C10.2770857,97.9120845 10.1770857,130.112084 13.3770857,132.812084 C21.8770857,130.812084 25.7770857,56.7120845 23.2770857,49.3120845 C20.1770857,48.8120845 15.7770857,56.7120845 6.57708573,49.2120845 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M5.67708573,3.4120845 C-2.22291427,7.4120845 5.07708573,43.9120845 9.17708573,48.0120845 C13.0770857,51.9120845 18.9770857,50.2120845 21.7770857,45.9120845 C25.5770857,39.9120845 27.1770857,22.4120845 27.4770857,15.1120845 C27.6770857,9.8120845 29.2770857,3.5120845 22.9770857,2.7120845 C19.9770857,2.3120845 7.97708573,2.3120845 5.67708573,3.4120845 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M6.57708573,49.2120845 C15.7770857,56.7120845 20.1770857,48.9120845 23.1770857,49.3120845 C25.7770857,56.7120845 21.8770857,130.712084 13.2770857,132.812084 C10.0770857,130.112084 10.2770857,98.0120845 9.77708573,91.3120845 C8.97708573,77.2120845 7.47708573,63.2120845 6.57708573,49.2120845 L6.57708573,49.2120845 Z M5.67708573,3.4120845 C-2.22291427,7.4120845 5.07708573,43.9120845 9.17708573,48.0120845 C13.0770857,51.9120845 18.9770857,50.2120845 21.7770857,45.9120845 C25.5770857,39.9120845 27.1770857,22.4120845 27.4770857,15.1120845 C27.6770857,9.8120845 29.2770857,3.5120845 22.9770857,2.7120845 C19.9770857,2.3120845 7.97708573,2.3120845 5.67708573,3.4120845 L5.67708573,3.4120845 Z M0.177085726,16.2120845 C0.977085726,28.5120845 3.47708573,40.8120845 4.67708573,53.3120845 L9.87708573,131.312084 C12.2770857,138.312084 17.0770857,134.112084 18.9770857,129.112084 C27.5770857,106.112084 23.9770857,54.1120845 27.3770857,34.5120845 C28.6770857,27.1120845 33.5770857,3.7120845 24.2770857,0.812084498 C20.4770857,-0.387915502 8.77708573,-0.287915502 5.07708573,1.3120845 C-0.722914274,3.8120845 -0.122914274,10.9120845 0.177085726,16.2120845 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-incisiv-41"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="incisiv-4.2" transform="translate(410.265599, 187.902425)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M6.83440137,52.3975747 C5.33440137,56.3975747 9.63440137,90.2975747 10.2344014,97.3975747 C10.7344014,104.497575 11.6344014,141.097575 13.6344014,144.097575 C22.2344014,143.497575 25.3344014,58.8975747 23.0344014,50.1975747 C21.3344014,51.2975747 19.2344014,55.5975747 15.5344014,55.8975747 C10.6344014,56.1975747 9.83440137,51.5975747 6.83440137,52.3975747 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M25.7344014,3.89757469 C22.2344014,2.79757469 11.8344014,2.69757469 8.03440137,3.29757469 C1.53440137,4.29757469 1.93440137,10.4975747 2.03440137,15.6975747 C2.23440137,23.3975747 3.13440137,41.9975747 7.53440137,47.9975747 C24.4344014,71.0975747 30.5344014,12.6975747 25.7344014,3.89757469 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M6.83440137,52.3975747 C9.83440137,51.5975747 10.6344014,56.1975747 15.6344014,55.7975747 C19.3344014,55.4975747 21.4344014,51.1975747 23.1344014,50.0975747 C25.4344014,58.7975747 22.2344014,143.397575 13.7344014,143.997575 C11.6344014,140.997575 10.8344014,104.397575 10.3344014,97.2975747 C9.63440137,90.2975747 5.33440137,56.3975747 6.83440137,52.3975747 L6.83440137,52.3975747 Z M25.7344014,3.89757469 C22.2344014,2.79757469 11.8344014,2.69757469 8.03440137,3.29757469 C1.53440137,4.29757469 1.93440137,10.4975747 2.03440137,15.6975747 C2.23440137,23.3975747 3.13440137,41.9975747 7.53440137,47.9975747 C24.4344014,71.0975747 30.5344014,12.6975747 25.7344014,3.89757469 L25.7344014,3.89757469 Z M0.134401374,21.6975747 C1.33440137,42.5975747 6.53440137,75.9975747 8.43440137,102.097575 C9.03440137,110.097575 9.23440137,138.497575 11.0344014,143.597575 C13.6344014,150.997575 18.0344014,143.897575 19.2344014,140.097575 C21.0344014,134.597575 22.0344014,127.297575 22.7344014,121.497575 C26.1344014,94.2975747 24.4344014,53.4975747 27.3344014,37.0975747 C28.8344014,28.6975747 33.8344014,3.39757469 22.5344014,1.09757469 C3.43440137,-3.00242531 -0.865598626,4.49757469 0.134401374,21.6975747 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-incisiv-42"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="canin-4.3" transform="translate(352.896860, 192.215389)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M6.30314026,55.3846111 C8.70314026,86.0846111 12.3031403,110.984611 9.80314026,141.984611 C9.50314026,145.484611 5.20314026,165.184611 14.1031403,157.384611 C23.5031403,148.984611 27.7031403,123.784611 29.3031403,111.684611 C30.4031403,103.184611 34.6031403,62.6846111 33.0031403,56.8846111 C28.8031403,54.2846111 26.1031403,71.8846111 6.30314026,55.3846111 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M19.2031403,2.28461106 C7.50314026,7.08461106 0.803140262,21.5846111 2.10314026,33.3846111 C3.20314026,43.2846111 9.10314026,63.7846111 22.7031403,60.0846111 C33.8031403,57.0846111 46.4031403,25.4846111 34.1031403,9.58461106 C31.0031403,5.68461106 24.3031403,0.184611061 19.2031403,2.28461106 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M29.3031403,111.684611 C27.7031403,123.884611 23.5031403,149.084611 14.1031403,157.384611 C5.30314026,165.184611 9.50314026,145.484611 9.80314026,141.984611 C12.2031403,110.984611 8.60314026,86.0846111 6.30314026,55.3846111 C26.2031403,71.8846111 28.8031403,54.2846111 33.1031403,56.8846111 C34.6031403,62.6846111 30.5031403,103.184611 29.3031403,111.684611 L29.3031403,111.684611 Z M19.2031403,2.28461106 C7.50314026,7.08461106 0.803140262,21.5846111 2.10314026,33.3846111 C3.20314026,43.2846111 9.10314026,63.7846111 22.7031403,60.0846111 C33.8031403,57.0846111 46.4031403,25.4846111 34.1031403,9.58461106 C31.0031403,5.68461106 24.3031403,0.184611061 19.2031403,2.28461106 L19.2031403,2.28461106 Z M40.8031403,33.3846111 C42.7031403,21.3846111 37.7031403,2.88461106 24.1031403,0.184611061 C12.8031403,-2.01538894 1.20314026,15.9846111 0.203140262,25.5846111 C-0.696859738,33.7846111 1.60314026,40.6846111 3.00314026,48.5846111 C5.70314026,63.2846111 8.50314026,107.384611 8.50314026,121.384611 C8.50314026,137.484611 2.90314026,164.084611 12.3031403,160.984611 C33.1031403,154.084611 34.4031403,75.0846111 36.2031403,55.0846111 C36.8031403,46.3846111 39.6031403,40.7846111 40.8031403,33.3846111 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-canin-43"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g
                id="premolar-4.4"
                transform="translate(297.187738, 194.744204)"
              >
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M33.1122621,43.0557962 C20.1122621,57.1557962 10.2122621,45.8557962 7.21226207,45.7557962 C6.21226207,47.2557962 8.91226207,90.2557962 8.81226207,96.6557962 C8.81226207,98.9557962 2.21226207,149.755796 14.1122621,134.955796 C22.8122621,124.155796 34.1122621,57.7557962 33.1122621,43.0557962 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M19.1122621,1.65579623 C17.7122621,2.05579623 9.31226207,9.05579623 7.51226207,10.4557962 C-5.88773793,21.3557962 6.21226207,50.4557962 21.6122621,47.7557962 C31.2122621,46.0557962 36.8122621,31.2557962 37.3122621,22.6557962 C37.9122621,11.1557962 21.4122621,0.955796228 19.1122621,1.65579623 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M33.1122621,43.0557962 C34.1122621,57.7557962 22.8122621,124.055796 14.0122621,135.055796 C2.11226207,149.855796 8.71226207,99.0557962 8.71226207,96.7557962 C8.81226207,90.3557962 6.11226207,47.3557962 7.11226207,45.8557962 C10.1122621,45.9557962 20.1122621,57.2557962 33.1122621,43.0557962 L33.1122621,43.0557962 Z M19.1122621,1.65579623 C17.7122621,2.05579623 9.31226207,9.05579623 7.51226207,10.4557962 C-5.88773793,21.3557962 6.21226207,50.4557962 21.6122621,47.7557962 C31.2122621,46.0557962 36.8122621,31.2557962 37.3122621,22.6557962 C37.9122621,11.1557962 21.4122621,0.955796228 19.1122621,1.65579623 L19.1122621,1.65579623 Z M39.1122621,26.8557962 C40.8122621,15.7557962 32.8122621,2.35579623 22.1122621,0.155796228 C13.6122621,-1.64420377 0.312262068,12.5557962 0.0122620679,21.9557962 C-0.187737932,27.9557962 2.11226207,35.4557962 3.01226207,41.6557962 C6.11226207,61.8557962 7.41226207,86.1557962 6.21226207,106.255796 C5.91226207,110.855796 0.112262068,146.055796 12.9122621,138.955796 C25.7122621,131.855796 33.8122621,63.6557962 35.5122621,46.4557962 C36.2122621,38.9557962 38.0122621,33.5557962 39.1122621,26.8557962 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-premolar-44"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g
                id="premolar-4.5"
                transform="translate(243.396981, 195.400702)"
              >
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M34.5030193,50.7992976 L35.8030193,46.3992976 L35.9030193,44.9992976 L34.6030193,45.0992976 C35.1030193,46.5992976 35.4030193,46.0992976 33.9030193,46.4992976 L33.3030193,47.7992976 L32.3030193,48.7992976 L31.1030193,49.4992976 L29.7030193,49.9992976 L28.2030193,50.9992976 C13.0030193,58.2992976 7.80301928,44.4992976 5.50301928,45.5992976 C4.30301928,54.0992976 12.8030193,93.7992976 13.3030193,115.099298 C13.4030193,120.599298 11.8030193,134.799298 15.6030193,138.399298 C23.3030193,139.799298 29.8030193,108.199298 30.8030193,102.299298 C33.5030193,86.1992976 33.7030193,67.3992976 34.5030193,50.7992976 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M18.9030193,2.09929756 C16.7030193,2.89929756 7.10301928,9.29929756 5.50301928,10.9992976 C-3.79698072,20.7992976 5.80301928,54.5992976 23.2030193,50.6992976 C34.4030193,48.0992976 38.8030193,32.8992976 39.5030193,22.9992976 C40.0030193,15.6992976 39.5030193,11.7992976 33.2030193,7.69929756 C29.2030193,5.19929756 23.7030193,0.399297555 18.9030193,2.09929756 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M28.2030193,51.0992976 C28.2030193,49.2992976 27.8030193,49.7992976 29.7030193,50.0992976 L31.1030193,49.5992976 L32.3030193,48.8992976 L33.3030193,47.8992976 L33.9030193,46.5992976 L34.6030193,45.1992976 C34.8030193,44.8992976 35.4030193,44.6992976 35.5030193,44.2992976 C35.6030193,43.8992976 35.8030193,44.7992976 35.9030193,45.0992976 L35.8030193,46.4992976 L34.5030193,50.8992976 C33.7030193,67.4992976 33.4030193,86.2992976 30.8030193,102.499298 C29.8030193,108.399298 23.3030193,139.999298 15.6030193,138.599298 C11.7030193,134.899298 13.4030193,120.799298 13.3030193,115.299298 C12.8030193,94.0992976 4.30301928,54.2992976 5.50301928,45.7992976 C7.70301928,44.5992976 12.9030193,58.3992976 28.2030193,51.0992976 L28.2030193,51.0992976 Z M18.9030193,2.09929756 C16.7030193,2.89929756 7.10301928,9.29929756 5.50301928,10.9992976 C-3.79698072,20.7992976 5.80301928,54.5992976 23.2030193,50.6992976 C34.4030193,48.0992976 38.8030193,32.8992976 39.5030193,22.9992976 C40.0030193,15.6992976 39.5030193,11.7992976 33.2030193,7.69929756 C29.2030193,5.19929756 23.7030193,0.399297555 18.9030193,2.09929756 L18.9030193,2.09929756 Z M41.2030193,27.1992976 C43.1030193,14.3992976 37.0030193,1.89929756 23.0030193,0.0992975553 C13.0030193,-1.20070244 0.403019276,10.4992976 0.00301927601,21.3992976 C-0.196980724,26.7992976 9.60301928,94.3992976 10.4030193,103.399298 C10.9030193,109.599298 8.80301928,142.799298 16.8030193,141.299298 C24.0030193,139.999298 29.9030193,117.199298 31.3030193,110.799298 C34.6030193,95.9992976 34.9030193,83.0992976 35.6030193,68.1992976 C36.4030193,52.4992976 39.0030193,42.0992976 41.2030193,27.1992976 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <polygon
                  fill="#989189"
                  points="27.9030193 51.1992976 30.9030193 49.5992976 27.6030193 49.0992976"
                ></polygon>
                <g
                  type="implant"
                  id="implant-premolar-45"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="molar-4.6" transform="translate(157.309774, 193.733725)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M63.990226,44.8662747 C49.290226,39.4662747 34.990226,46.3662747 20.290226,46.1662747 C5.69022597,45.9662747 13.990226,55.0662747 10.890226,85.9662747 C10.090226,93.5662747 1.39022597,127.866275 1.99022597,130.066275 C5.49022597,142.666275 23.290226,111.466275 24.590226,108.866275 C26.690226,104.666275 39.790226,69.9662747 45.790226,88.8662747 C48.090226,96.1662747 46.290226,105.966275 43.790226,113.066275 C33.390226,143.066275 58.390226,127.766275 63.190226,94.5662747 C65.690226,77.8662747 62.290226,62.2662747 63.990226,44.8662747 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M71.090226,21.8662747 C72.190226,15.3662747 71.490226,4.16627468 63.990226,1.66627468 C53.890226,-1.63372532 50.590226,8.86627468 44.290226,6.26627468 C35.790226,2.76627468 33.290226,-0.333725318 23.590226,5.56627468 C14.990226,10.7662747 8.29022597,4.56627468 6.79022597,15.8662747 C5.99022597,22.0662747 7.19022597,35.3662747 11.190226,40.4662747 C15.390226,45.6662747 29.590226,44.0662747 35.390226,42.1662747 C53.590226,36.0662747 65.990226,50.4662747 71.090226,21.8662747 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M63.990226,44.8662747 C62.290226,62.2662747 65.690226,77.8662747 63.290226,94.4662747 C58.490226,127.666275 33.490226,142.966275 43.890226,112.966275 C46.290226,105.866275 48.190226,96.1662747 45.890226,88.7662747 C39.890226,69.8662747 26.790226,104.566275 24.690226,108.766275 C23.390226,111.366275 5.59022597,142.466275 2.09022597,129.966275 C1.49022597,127.766275 10.190226,93.4662747 10.990226,85.8662747 C14.090226,54.9662747 5.79022597,45.8662747 20.390226,46.0662747 C34.990226,46.3662747 49.290226,39.4662747 63.990226,44.8662747 L63.990226,44.8662747 Z M71.090226,21.8662747 C72.190226,15.3662747 71.490226,4.16627468 63.990226,1.66627468 C53.890226,-1.63372532 50.590226,8.86627468 44.290226,6.26627468 C35.790226,2.76627468 33.290226,-0.333725318 23.590226,5.56627468 C14.990226,10.7662747 8.29022597,4.56627468 6.79022597,15.8662747 C5.99022597,22.0662747 7.19022597,35.3662747 11.190226,40.4662747 C15.390226,45.6662747 29.590226,44.0662747 35.390226,42.1662747 C53.590226,36.0662747 65.990226,50.4662747 71.090226,21.8662747 L71.090226,21.8662747 Z M73.090226,22.8662747 C74.390226,14.4662747 72.190226,1.76627468 62.090226,0.166274682 C55.890226,-0.833725318 48.290226,3.06627468 37.490226,1.56627468 C30.190226,0.566274682 18.390226,3.66627468 11.890226,7.26627468 C2.39022597,12.4662747 4.49022597,24.5662747 6.09022597,33.5662747 C16.690226,91.3662747 1.99022597,105.866275 0.0902259654,125.966275 C-0.909774035,137.166275 6.59022597,137.066275 13.290226,130.466275 C21.490226,122.366275 35.890226,88.9662747 39.490226,87.7662747 C47.290226,85.2662747 43.390226,107.266275 42.390226,110.566275 C30.690226,149.666275 65.590226,129.666275 66.390226,82.7662747 C66.590226,71.9662747 64.890226,61.6662747 65.790226,50.7662747 C66.690226,39.3662747 71.690226,31.6662747 73.090226,22.8662747 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-46"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="molar-4.7" transform="translate(89.412616, 193.692781)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M8.487384,36.9072194 C10.787384,53.0072194 13.287384,69.1072194 10.087384,85.3072194 C9.387384,88.9072194 -4.412616,122.607219 5.887384,119.107219 C11.987384,117.007219 22.987384,93.9072194 26.287384,87.5072194 C36.287384,68.4072194 38.387384,81.8072194 32.387384,103.507219 C31.387384,107.107219 26.887384,118.407219 30.487384,121.007219 C34.687384,124.007219 41.287384,113.807219 42.787384,110.907219 C49.787384,97.4072194 56.087384,64.2072194 58.487384,47.5072194 C59.987384,36.9072194 56.487384,39.6072194 48.587384,40.1072194 C28.787384,41.4072194 22.887384,44.5072194 8.487384,36.9072194 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M51.287384,1.30721937 C45.087384,2.50721937 40.087384,9.70721937 33.987384,8.70721937 C27.887384,7.70721937 22.787384,0.507219373 13.787384,4.90721937 C3.387384,10.0072194 3.687384,33.8072194 17.587384,38.9072194 C27.287384,42.5072194 30.587384,37.0072194 38.487384,37.2072194 C45.087384,37.4072194 52.687384,40.6072194 58.587384,34.7072194 C66.087384,27.4072194 66.387384,-1.59278063 51.287384,1.30721937 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M5.887384,119.107219 C-4.412616,122.607219 9.387384,88.9072194 10.087384,85.3072194 C13.287384,69.1072194 10.687384,53.0072194 8.487384,36.9072194 C22.987384,44.5072194 28.787384,41.4072194 48.487384,40.2072194 C56.387384,39.7072194 59.987384,37.0072194 58.387384,47.6072194 C55.987384,64.3072194 49.687384,97.5072194 42.687384,111.007219 C41.187384,113.907219 34.587384,124.107219 30.387384,121.107219 C26.787384,118.507219 31.287384,107.207219 32.287384,103.607219 C38.287384,81.9072194 36.187384,68.5072194 26.187384,87.6072194 C22.987384,93.9072194 11.987384,117.007219 5.887384,119.107219 L5.887384,119.107219 Z M51.287384,1.30721937 C45.087384,2.50721937 40.087384,9.70721937 33.987384,8.70721937 C27.887384,7.70721937 22.787384,0.507219373 13.787384,4.90721937 C3.387384,10.0072194 3.687384,33.8072194 17.587384,38.9072194 C27.287384,42.5072194 30.587384,37.0072194 38.487384,37.2072194 C45.087384,37.4072194 52.687384,40.6072194 58.587384,34.7072194 C66.087384,27.4072194 66.387384,-1.59278063 51.287384,1.30721937 L51.287384,1.30721937 Z M64.687384,27.9072194 C65.887384,20.6072194 66.187384,7.00721937 59.387384,2.20721937 C51.187384,-3.49278063 44.887384,3.50721937 37.587384,4.70721937 C29.087384,6.10721937 22.987384,-0.492780627 13.987384,3.30721937 C-5.712616,11.7072194 14.287384,51.5072194 7.987384,84.2072194 C6.287384,93.0072194 2.387384,100.307219 0.487383998,108.607219 C-1.012616,115.407219 0.687383998,126.307219 9.287384,119.507219 C15.187384,114.807219 30.887384,82.1072194 32.887384,83.3072194 C38.487384,86.6072194 18.087384,125.507219 32.587384,123.707219 C45.287384,122.107219 52.087384,91.6072194 54.387384,81.5072194 L64.687384,27.9072194 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-47"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
              <g id="molar-4.8" transform="translate(0.434070, 197.736508)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M53.6659297,33.4634922 C46.0659297,35.1634922 42.9659297,38.3634922 33.6659297,38.4634922 C27.7659297,38.5634922 19.6659297,33.8634922 19.2659297,40.0634922 C18.3659297,57.1634922 21.3659297,67.6634922 13.4659297,84.6634922 C10.0659297,91.8634922 6.06592972,94.4634922 2.86592972,99.8634922 C-1.83407028,107.963492 17.3659297,110.763492 36.3659297,73.5634922 C42.9659297,60.5634922 44.7659297,75.1634922 35.5659297,95.7634922 C34.7659297,97.6634922 27.6659297,109.563492 32.8659297,109.464119 C36.4659297,109.464119 44.9659297,102.263492 47.3659297,99.5634922 C60.1659297,84.8634922 84.1659297,26.7634922 53.6659297,33.4634922 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M60.1659297,1.76349222 C47.1659297,4.46349222 54.1659297,4.96349222 40.4659297,2.96349222 C37.3659297,2.56349222 23.1659297,5.26349222 20.2659297,6.36349222 C12.4659297,9.26349222 12.0659297,34.5634922 29.9659297,36.2634922 C39.0659297,37.0634922 41.3659297,35.3634922 49.1659297,32.3634922 C55.1659297,30.1634922 67.6659297,31.4634922 69.7659297,28.9634922 C71.2659297,27.2634922 82.6659297,-2.93650778 60.1659297,1.76349222 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M53.6659297,33.4634922 C84.1659297,26.6634922 60.2659297,84.8634922 47.2659297,99.4634922 C44.8659297,102.163492 36.3659297,109.363492 32.7659297,109.363492 C27.5659297,109.363492 34.6659297,97.5634922 35.4659297,95.6634922 C44.6659297,75.0634922 42.8659297,60.4634922 36.2659297,73.4634922 C17.3659297,110.663492 -1.93407028,107.863492 2.76592972,99.7634922 C5.96592972,94.2634922 9.96592972,91.7634922 13.3659297,84.5634922 C21.2659297,67.5634922 18.2659297,57.0634922 19.1659297,39.9634922 C19.4659297,33.7634922 27.5659297,38.4634922 33.5659297,38.3634922 C42.9659297,38.3634922 46.0659297,35.1634922 53.6659297,33.4634922 L53.6659297,33.4634922 Z M60.1659297,1.76349222 C47.1659297,4.46349222 54.1659297,4.96349222 40.4659297,2.96349222 C37.3659297,2.56349222 23.1659297,5.26349222 20.2659297,6.36349222 C12.4659297,9.26349222 12.0659297,34.5634922 29.9659297,36.2634922 C39.0659297,37.0634922 41.3659297,35.3634922 49.1659297,32.3634922 C55.1659297,30.1634922 67.6659297,31.4634922 69.7659297,28.9634922 C71.2659297,27.2634922 82.6659297,-2.93650778 60.1659297,1.76349222 L60.1659297,1.76349222 Z M76.1659297,15.8634922 C78.1659297,-5.83650778 54.0659297,0.963492221 50.0659297,1.16349222 C19.3659297,2.96349222 10.1659297,-2.53650778 16.1659297,35.1634922 C24.7659297,89.1634922 -7.53407028,97.9634922 1.66592972,105.363492 C7.26592972,109.963492 17.1659297,103.563492 21.2659297,99.5634922 C26.5659297,94.4634922 31.1659297,87.3634922 34.7659297,80.9634922 C47.6659297,57.8634922 29.2659297,104.363492 29.2659297,104.563492 C27.0659297,114.763492 36.2659297,111.363492 41.3659297,107.663492 C62.3659297,92.3634922 67.7659297,65.2634922 70.4659297,41.6634922 C71.4659297,33.0634922 75.5659297,22.2634922 76.1659297,15.8634922 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-48"
                  class="implant absolute z1"
                  v-html="svg.implant.jos"
                ></g>
              </g>
            </g>
            <g ref="punte-bottom" class="dinti-top">
              <g
                class="relative"
                id="molar-2.8"
                transform="translate(891.328698, 58.237851)"
              >
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M25.8713016,50.5621487 C18.9713016,48.9621487 25.7713016,21.8621487 26.3713016,17.2621487 C28.6713016,0.962148674 13.1713016,28.5621487 12.2713016,30.8621487 C7.87130155,42.0621487 6.37130155,55.9621487 5.47130155,67.8621487 C4.27130155,84.3621487 10.9713016,67.8621487 25.0713016,66.7621487 C41.4713016,65.4621487 47.2713016,82.0621487 45.2713016,62.0621487 C43.9713016,49.0621487 44.4713016,33.8621487 47.3713016,21.1621487 C52.0713016,0.562148674 42.4713016,8.06214867 35.8713016,19.6621487 C31.4713016,27.4621487 27.1713016,48.8621487 25.8713016,50.5621487 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M49.6713016,97.3621487 C58.7713016,69.1621487 14.0713016,56.0621487 3.17130155,86.7621487 C-1.82869845,100.862149 5.27130155,116.962149 21.0713016,106.762149 C32.0713016,99.5621487 30.8713016,106.662149 37.1713016,108.062149 C42.3713016,109.262149 48.1713016,101.862149 49.6713016,97.3621487 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M49.6713016,97.3621487 C48.1713016,101.862149 42.4713016,109.262149 37.1713016,107.962149 C30.8713016,106.462149 32.0713016,99.4621487 21.0713016,106.662149 C5.37130155,116.962149 -1.82869845,100.762149 3.17130155,86.6621487 C14.0713016,56.0621487 58.7713016,69.1621487 49.6713016,97.3621487 L49.6713016,97.3621487 Z M25.8713016,50.5621487 C18.9713016,48.9621487 25.7713016,21.8621487 26.3713016,17.2621487 C28.6713016,0.962148674 13.1713016,28.5621487 12.2713016,30.8621487 C7.87130155,42.0621487 6.37130155,55.9621487 5.47130155,67.8621487 C4.27130155,84.3621487 10.9713016,67.8621487 25.0713016,66.7621487 C41.4713016,65.4621487 47.2713016,82.0621487 45.2713016,62.0621487 C43.9713016,49.0621487 44.4713016,33.8621487 47.3713016,21.1621487 C52.0713016,0.562148674 42.4713016,8.06214867 35.8713016,19.6621487 C31.4713016,27.4621487 27.1713016,48.8621487 25.8713016,50.5621487 L25.8713016,50.5621487 Z M25.2713016,43.9621487 C23.6713016,26.0621487 33.4713016,1.86214867 42.3713016,1.26214867 C44.7713016,6.16214867 35.5713016,14.8621487 32.4713016,21.1621487 C28.1713016,29.9621487 28.1713016,36.9621487 25.2713016,43.9621487 L25.2713016,43.9621487 Z M49.4713016,4.56214867 L46.5713016,4.46214867 L45.2713016,3.96214867 L44.9713016,0.162148674 C37.6713016,-0.537851326 37.9713016,0.962148674 33.2713016,5.96214867 C28.6713016,10.8621487 14.4713016,6.46214867 6.77130155,41.5621487 C3.77130155,55.2621487 4.27130155,67.7621487 2.17130155,80.9621487 C0.671301555,90.2621487 -4.72869845,109.162149 10.3713016,111.762149 C18.0713016,113.062149 20.1713016,108.662149 26.0713016,107.162149 C31.8713016,105.662149 34.2713016,111.162149 40.0713016,110.062149 C52.2713016,107.862149 53.9713016,90.9621487 51.2713016,81.1621487 C39.4713016,39.7621487 55.1713016,19.7621487 49.4713016,4.56214867 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M25.2713016,43.9621487 C28.1713016,36.9621487 28.1713016,30.0621487 32.4713016,21.1621487 C35.4713016,14.8621487 44.7713016,6.16214867 42.3713016,1.26214867 C33.4713016,1.86214867 23.6713016,25.9621487 25.2713016,43.9621487 Z"
                  fill="#FFFFFF"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-28"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="molar-2.7" transform="translate(815.389428, 40.683488)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M27.9105721,8.91651173 C7.61057212,29.2165117 7.81057212,58.0165117 5.91057212,84.8165117 C5.11057212,95.5165117 11.2105721,81.4165117 36.4105721,83.2165117 C50.1105721,84.2165117 47.2105721,91.5165117 50.7105721,55.0165117 C51.7105721,44.7165117 52.7105721,21.4165117 44.9105721,13.5165117 C36.1105721,4.51651173 38.7105721,30.8165117 35.5105721,45.7165117 C27.8105721,80.9165117 19.9105721,55.8165117 27.5105721,25.7165117 C29.3105721,19.1165117 31.8105721,15.1165117 27.9105721,8.91651173 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M53.7105721,111.916512 C58.1105721,98.2165117 48.3105721,84.5165117 32.9105721,85.4165117 C18.9105721,86.3165117 5.61057212,87.4165117 2.51057212,103.716512 C-0.389427881,118.616512 9.91057212,134.516512 23.7105721,124.916512 C36.3105721,116.116512 30.3105721,122.716512 40.8105721,122.716512 C46.0105721,122.616512 52.1105721,116.816512 53.7105721,111.916512 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M53.7105721,111.916512 C52.1105721,116.916512 46.0105721,122.616512 40.7105721,122.716512 C30.3105721,122.716512 36.2105721,116.116512 23.6105721,124.916512 C9.81057212,134.516512 -0.489427881,118.716512 2.41057212,103.716512 C5.61057212,87.4165117 18.8105721,86.3165117 32.8105721,85.4165117 C48.3105721,84.4165117 58.1105721,98.2165117 53.7105721,111.916512 L53.7105721,111.916512 Z M27.9105721,8.91651173 C7.61057212,29.2165117 7.81057212,58.0165117 5.91057212,84.8165117 C5.11057212,95.5165117 11.2105721,81.4165117 36.4105721,83.2165117 C50.1105721,84.2165117 47.2105721,91.5165117 50.7105721,55.0165117 C51.7105721,44.7165117 52.7105721,21.4165117 44.9105721,13.5165117 C36.1105721,4.51651173 38.7105721,30.8165117 35.5105721,45.7165117 C27.8105721,80.9165117 19.9105721,55.8165117 27.5105721,25.7165117 C29.3105721,19.1165117 31.8105721,15.1165117 27.9105721,8.91651173 L27.9105721,8.91651173 Z M27.9105721,59.8165117 C25.1105721,54.1165117 27.6105721,34.2165117 29.3105721,27.4165117 C30.7105721,21.6165117 32.4105721,18.0165117 32.9105721,11.8165117 C33.3105721,7.31651173 33.9105721,2.81651173 39.0105721,1.51651173 C44.5105721,8.11651173 37.7105721,6.41651173 36.3105721,12.3165117 C35.3105721,17.0165117 36.5105721,52.3165117 27.9105721,59.8165117 L27.9105721,59.8165117 Z M36.1105721,0.21651173 C36.1105721,0.21651173 23.4105721,10.3165117 22.8105721,11.0165117 C7.81057212,27.4165117 6.91057212,48.5165117 5.61057212,66.7165117 C4.61057212,80.4165117 0.310572119,96.7165117 0.0105721194,109.016512 C-0.389427881,123.616512 10.6105721,134.516512 24.6105721,126.716512 C36.8105721,119.916512 47.3105721,130.316512 54.5105721,114.616512 C60.7105721,101.116512 49.9105721,97.0165117 51.3105721,74.2165117 C52.2105721,59.3165117 55.2105721,44.6165117 53.1105721,29.6165117 C52.2105721,24.4165117 43.9105721,-2.68348827 36.1105721,0.21651173 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M27.9105721,59.8165117 C36.5105721,52.3165117 35.3105721,17.0165117 36.3105721,12.4165117 C37.6105721,6.51651173 44.5105721,8.21651173 39.0105721,1.61651173 C33.9105721,2.91651173 33.2105721,7.31651173 32.9105721,11.9165117 C32.4105721,18.0165117 30.7105721,21.7165117 29.3105721,27.5165117 C27.6105721,34.3165117 25.1105721,54.1165117 27.9105721,59.8165117 Z"
                  fill="#FFFFFF"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-27"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="molar-2.6" transform="translate(734.335343, 37.500000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M54.6646568,10 C44.5646568,26.1 31.0646568,89.8 24.5646568,51.2 C21.1646568,31.1 31.2646568,12.6 23.3646568,13 C12.9646568,13.6 6.86465681,37.2 6.16465681,45.5 C5.76465681,50.3 5.46465681,87.8 8.16465681,88.6 C9.56465681,89 13.3646568,86.9 15.0646568,86.3 C23.4646568,83.8 39.7646568,87.7 48.4646568,88.4 C54.0646568,88.9 52.3646568,76.4 52.5646568,72.1 C54.0646568,50.6 66.6646568,21.8 54.6646568,10 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M16.5646568,87.6 C3.06465681,90.7 -1.73534319,116.2 3.96465681,124.9 C8.06465681,131.1 16.2646568,132.1 22.3646568,128.7 C36.6646568,120.9 28.0646568,126.3 38.7646568,129.9 C44.5646568,131.9 54.3646568,129.3 57.0646568,123.5 C60.1646568,117 57.7646568,107.5 55.6646568,101 C52.9646568,92.8 49.3646568,90 40.5646568,89.1 C36.1646568,88.6 32.3646568,89.4 27.9646568,88.9 C24.5646568,88.5 20.0646568,86.8 16.5646568,87.6 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M16.5646568,87.6 C20.0646568,86.8 24.5646568,88.5 27.9646568,88.9 C32.3646568,89.4 36.1646568,88.6 40.5646568,89.1 C49.3646568,90 52.9646568,92.8 55.6646568,101 C57.8646568,107.5 60.1646568,117 57.0646568,123.5 C54.2646568,129.4 44.5646568,131.9 38.7646568,129.9 C28.0646568,126.3 36.6646568,120.9 22.3646568,128.7 C16.1646568,132.1 7.96465681,131.1 3.96465681,124.9 C-1.73534319,116.2 3.06465681,90.8 16.5646568,87.6 L16.5646568,87.6 Z M54.6646568,10 C44.5646568,26.1 31.0646568,89.8 24.5646568,51.2 C21.1646568,31.1 31.2646568,12.6 23.3646568,13 C12.9646568,13.6 6.86465681,37.2 6.16465681,45.5 C5.76465681,50.3 5.46465681,87.8 8.16465681,88.6 C9.56465681,89 13.3646568,86.9 15.0646568,86.3 C23.4646568,83.8 39.7646568,87.7 48.4646568,88.4 C54.0646568,88.9 52.3646568,76.4 52.5646568,72.1 C54.0646568,50.6 66.6646568,21.8 54.6646568,10 L54.6646568,10 Z M30.0646568,61 C24.3646568,54 25.7646568,35.2 27.4646568,26.7 C28.7646568,20.3 36.1646568,3.4 42.7646568,2 C53.7646568,9.6 36.1646568,57.4 30.0646568,61 L30.0646568,61 Z M41.0646568,-2.84217094e-14 C37.2646568,3.3 32.3646568,11 28.6646568,10.8 C20.5646568,10.4 19.9646568,10.3 13.9646568,17.3 C-1.23534319,35.1 6.16465681,64.1 3.76465681,89.4 C2.66465681,101.2 -6.83534319,125.5 9.36465681,131.5 C19.5646568,135.3 23.1646568,128.4 29.6646568,128.6 C36.4646568,128.8 38.2646568,134.7 49.2646568,131.7 C57.5646568,129.4 60.4646568,123.4 60.4646568,115.2 C60.5646568,99.7 51.4646568,96.4 55.5646568,65.8 C57.6646568,50.5 64.1646568,32.3 61.1646568,16.9 C58.8646568,4.7 54.4646568,10.4 49.8646568,12.1 C47.5646568,12.8 48.7646568,3.8 41.0646568,-2.84217094e-14 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M30.0646568,61 C36.1646568,57.4 53.7646568,9.6 42.7646568,2 C36.1646568,3.4 28.7646568,20.3 27.4646568,26.7 C25.6646568,35.2 24.3646568,54 30.0646568,61 Z"
                  fill="#FFFFFF"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-26"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="premolar-2.5" transform="translate(682.048181, 27.200000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M9.15181861,93.1 C11.0518186,94.2 14.6518186,89.2 19.3518186,88.5 C26.6518186,87.4 29.2518186,92.7 31.7518186,92.4 C32.2518186,90.6 29.7518186,54.2 29.6518186,48.3 C29.5518186,40 29.6518186,31.6 29.9518186,23.3 C30.2518186,17.3 32.2518186,6.4 27.1518186,2.3 C18.6518186,7.4 7.15181861,82.7 9.15181861,93.1 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M17.7518186,90.9 C8.75181861,94.2 4.05181861,110.2 2.35181861,118.6 C1.15181861,124.6 0.751818612,130.4 5.85181861,134.5 C10.7518186,138.5 14.9518186,141.2 21.4518186,139.8 C36.8518186,136.4 39.3518186,129.1 37.0518186,114.2 C35.7518186,105.6 30.2518186,86.4 17.7518186,90.9 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M17.7518186,90.9 C30.2518186,86.4 35.7518186,105.6 37.0518186,114.2 C39.3518186,129.1 36.8518186,136.4 21.4518186,139.8 C14.9518186,141.2 10.6518186,138.5 5.85181861,134.5 C0.751818612,130.3 1.15181861,124.5 2.35181861,118.6 C4.05181861,110.2 8.65181861,94.2 17.7518186,90.9 L17.7518186,90.9 Z M9.15181861,93.1 C11.0518186,94.2 14.6518186,89.2 19.3518186,88.5 C26.6518186,87.4 29.2518186,92.7 31.7518186,92.4 C32.2518186,90.6 29.7518186,54.2 29.6518186,48.3 C29.5518186,40 29.6518186,31.6 29.9518186,23.3 C30.2518186,17.3 32.2518186,6.4 27.1518186,2.3 C18.6518186,7.4 7.15181861,82.7 9.15181861,93.1 L9.15181861,93.1 Z M25.6518186,0 C10.8518186,21.5 9.65181861,70.1 6.15181861,95.6 C5.25181861,102.3 3.05181861,107.4 1.35181861,113.8 C-8.54818139,151 39.1518186,150.2 39.8518186,122.4 C40.1518186,111.5 34.7518186,95.8 33.6518186,83 C31.0518186,53.3 32.1518186,42.5 32.5518186,15.4 C32.5518186,7.8 33.3518186,2.6 25.6518186,0 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-premolar-25"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="premolar-2.4" transform="translate(636.188814, 31.583297)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M35.0111865,89.4167026 C36.2111865,85.7167026 34.2111865,39.2167026 34.6111865,29.9167026 C34.9111865,23.0167026 37.7111865,7.7167026 31.0111865,3.6167026 C10.6111865,-8.9832974 6.61118649,81.9167026 7.51118649,87.5167026 C8.31118649,92.3167026 11.5111865,87.7167026 14.0111865,86.2167026 C25.7111865,79.4167026 32.7111865,89.8167026 35.0111865,89.4167026 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M38.9111865,122.016703 C45.6111865,98.2167026 19.1111865,66.4167026 5.31118649,102.416703 C0.211186493,115.716703 -0.888813507,124.816703 11.8111865,133.016703 C22.2111865,139.716703 36.1111865,131.916703 38.9111865,122.016703 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M38.9111865,122.016703 C36.1111865,131.916703 22.2111865,139.816703 11.8111865,133.016703 C-0.888813507,124.716703 0.211186493,115.716703 5.31118649,102.416703 C19.1111865,66.3167026 45.6111865,98.2167026 38.9111865,122.016703 L38.9111865,122.016703 Z M35.0111865,89.4167026 C36.2111865,85.7167026 34.2111865,39.2167026 34.6111865,29.9167026 C34.9111865,23.0167026 37.7111865,7.7167026 31.0111865,3.6167026 C10.6111865,-8.9832974 6.61118649,81.9167026 7.51118649,87.5167026 C8.31118649,92.3167026 11.5111865,87.7167026 14.0111865,86.2167026 C25.7111865,79.4167026 32.7111865,89.8167026 35.0111865,89.4167026 L35.0111865,89.4167026 Z M23.5111865,0.516702602 C6.71118649,6.6167026 6.31118649,84.3167026 4.71118649,95.3167026 C3.81118649,101.616703 0.911186493,107.116703 0.211186493,113.516703 C-3.38881351,144.216703 40.2111865,146.416703 41.5111865,115.916703 C41.8111865,108.316703 39.8111865,103.516703 38.6111865,96.3167026 C35.3111865,75.9167026 38.0111865,13.7167026 36.8111865,8.9167026 C35.5111865,3.0167026 29.4111865,-1.5832974 23.5111865,0.516702602 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <polygon
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  fill="#989189"
                  points="28.2111865 2.5167026 25.0111865 13.9167026 22.4111865 24.6167026 21.2111865 33.1167026 20.6111865 42.9167026 20.8111865 54.6167026 20.6111865 57.1167026 21.8111865 54.6167026 22.2111865 45.5167026 23.7111865 32.7167026 26.1111865 18.8167026 28.6111865 8.3167026 30.6111865 2.4167026"
                ></polygon>
                <g
                  type="implant"
                  id="implant-premolar-24"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="canin-2.3" transform="translate(583.207182, 0.300000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M34.9928176,2.1 C20.8928176,14.3 18.1928176,32.9 15.1928176,50.6 C13.6928176,59.4 7.59281757,103.7 8.89281757,109.7 C10.3928176,110.6 15.3928176,103.8 21.2928176,103 C30.0928176,101.9 32.5928176,109.7 35.7928176,108.6 C37.4928176,104.7 35.1928176,63.2 34.9928176,55.3 C34.7928176,45.7 34.6928176,36.1 35.2928176,26.5 C35.6928176,19.1 38.4928176,8.5 34.9928176,2.1 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M20.7928176,104.9 C9.19281757,107.7 3.59281757,128.2 1.89281757,138.3 C-0.807182426,154.5 11.1928176,181.4 38.4928176,155.9 C48.4928176,146.7 37.3928176,101 20.7928176,104.9 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M20.7928176,104.9 C37.3928176,100.9 48.3928176,146.7 38.4928176,155.9 C11.1928176,181.4 -0.707182426,154.5 1.89281757,138.3 C3.69281757,128.3 9.19281757,107.7 20.7928176,104.9 L20.7928176,104.9 Z M34.9928176,2.1 C20.8928176,14.3 18.1928176,32.9 15.1928176,50.6 C13.6928176,59.4 7.59281757,103.7 8.89281757,109.7 C10.3928176,110.6 15.3928176,103.8 21.2928176,103 C30.0928176,101.9 32.5928176,109.7 35.7928176,108.6 C37.4928176,104.7 35.1928176,63.2 34.9928176,55.3 C34.7928176,45.7 34.6928176,36.1 35.2928176,26.5 C35.6928176,19.1 38.4928176,8.5 34.9928176,2.1 L34.9928176,2.1 Z M33.9928176,-2.84217094e-14 C6.89281757,23.3 12.1928176,87 1.49281757,130.9 C-1.60718243,143.7 -1.00718243,162.9 14.3928176,167.8 C25.7928176,171.4 43.1928176,159.4 44.2928176,147 C45.1928176,136.4 39.3928176,119 38.6928176,98.7 C37.8928176,77.5 35.9928176,41 38.0928176,20.3 C38.7928176,12.2 40.8928176,5.4 33.9928176,-2.84217094e-14 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-canin-23"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="incisiv-2.2" transform="translate(537.197924, 25.500000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M8.90207555,89.2 L9.10207555,92.4 L14.4020756,87.8 C27.3020756,83.5 28.6020756,92.1 31.7020756,92.1 C32.4020756,90.1 30.4020756,53.2 30.4020756,47.1 C30.5020756,39.3 30.8020756,31.2 32.0020756,23.5 C32.9020756,17.7 36.7020756,8.3 34.0020756,3.1 C16.7020756,-2 9.60207555,78.9 8.90207555,89.2 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M2.30207555,134.1 C4.10207555,146.1 30.1020756,142 34.1020756,136.4 C37.8020756,131.1 35.0020756,105 32.2020756,98.8 C19.2020756,69.5 -1.29792445,109.4 2.30207555,134.1 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M2.30207555,134.1 C-1.29792445,109.4 19.2020756,69.5 32.2020756,98.7 C35.0020756,105 37.8020756,131.1 34.1020756,136.3 C30.1020756,142 4.00207555,146.1 2.30207555,134.1 L2.30207555,134.1 Z M14.5020756,87.8 C27.4020756,83.5 28.7020756,92.1 31.8020756,92.1 C32.5020756,90.1 30.5020756,53.2 30.5020756,47.1 C30.6020756,39.3 30.9020756,31.2 32.1020756,23.5 C33.0020756,17.7 36.8020756,8.3 34.1020756,3.1 C16.7020756,-1.9 9.60207555,79 9.00207555,89.3 C7.70207555,92.7 7.40207555,92.2 9.00207555,95.2 L14.5020756,87.8 L14.5020756,87.8 Z M33.3020756,0 C15.6020756,3.8 10.4020756,53 8.70207555,67.9 C7.10207555,82.3 6.60207555,94.1 3.10207555,108.3 C0.702075554,118 -4.79792445,140.3 9.20207555,143.2 C23.7020756,146.2 38.1020756,143 38.0020756,126 C37.9020756,111.9 34.9020756,97.4 34.0020756,83 C33.1020756,68.2 32.3020756,52.8 33.0020756,38 C34.0020756,18.4 41.0020756,5.5 33.3020756,0 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M8.90207555,89.2 C7.60207555,92.6 7.30207555,92.1 8.90207555,95.1 L14.4020756,87.8 L9.10207555,92.4 L8.90207555,89.2 Z"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-incisiv-22"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="incisiv-2.1" transform="translate(488.328086, 20.285366)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M33.5719138,84.4146338 L38.2719138,87.8146338 L38.3719138,82.9146338 C38.3719138,69.6146338 36.6719138,55.9146338 36.4719138,42.4146338 C36.3719138,33.9146338 39.1719138,8.0146338 34.4719138,2.7146338 C17.9719138,-1.5853662 7.17191375,81.9146338 9.77191375,90.4146338 C13.2719138,91.0146338 17.8719138,75.5146338 33.5719138,84.4146338 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M44.0719138,135.314634 C46.7719138,125.214634 43.2719138,100.614634 37.3719138,91.6146338 C27.8719138,77.0146338 14.0719138,85.7146338 8.87191375,98.5146338 C6.27191375,104.914634 -0.128086246,136.914634 3.17191375,141.914634 C6.07191375,146.114634 21.6719138,144.414634 26.6719138,144.314634 C34.5719138,144.114634 41.6719138,144.314634 44.0719138,135.314634 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M44.0719138,135.314634 C41.6719138,144.314634 34.5719138,144.114634 26.7719138,144.314634 C21.7719138,144.414634 6.17191375,146.114634 3.27191375,141.914634 C-0.0280862463,137.014634 6.37191375,104.914634 8.97191375,98.5146338 C14.1719138,85.7146338 27.9719138,77.0146338 37.4719138,91.6146338 C43.2719138,100.614634 46.7719138,125.214634 44.0719138,135.314634 L44.0719138,135.314634 Z M38.3719138,82.8146338 C38.3719138,69.5146338 36.6719138,55.8146338 36.4719138,42.3146338 C36.3719138,33.8146338 39.1719138,7.9146338 34.4719138,2.6146338 C17.9719138,-1.6853662 7.17191375,81.8146338 9.77191375,90.3146338 C13.2719138,91.0146338 17.9719138,75.5146338 33.5719138,84.3146338 C35.2719138,86.1146338 37.3719138,89.4146338 39.2719138,89.8146338 L38.3719138,82.8146338 L38.3719138,82.8146338 Z M30.1719138,0.514633795 C15.3719138,6.4146338 9.67191375,62.3146338 8.37191375,75.9146338 C6.87191375,91.5146338 4.57191375,103.614634 2.17191375,118.814634 C-0.828086246,138.014634 -4.32808625,147.314634 18.7719138,146.814634 C36.4719138,146.414634 46.6719138,147.514634 46.8719138,126.214634 C47.0719138,110.714634 42.5719138,97.9146338 41.0719138,82.8146338 C39.5719138,67.5146338 38.6719138,51.6146338 38.6719138,36.2146338 C38.6719138,31.1146338 43.1719138,-4.6853662 30.1719138,0.514633795 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M33.5719138,84.4146338 C35.2719138,86.2146338 37.3719138,89.5146338 39.2719138,89.9146338 L38.3719138,82.9146338 L38.2719138,87.8146338 L33.5719138,84.4146338 Z"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-incisiv-21"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="incisiv-1.1" transform="translate(432.604000, 20.500000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M8.496,83.9 L8.596,87.3 L13.596,84.1 C29.296,75.2 33.696,90.7 37.396,90.1 C38.796,85.6 33.296,47.4 31.996,40.3 C30.596,32.4 23.696,1.3 13.196,2.1 C7.596,6.5 10.796,33.1 10.696,41 C10.496,55.6 8.996,69.6 8.496,83.9 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M2.796,133.1 C4.196,142.4 9.096,143.7 17.496,144 C22.296,144.2 39.096,145.2 42.396,143.1 C48.596,139.3 41.296,107.2 38.996,100.5 C34.996,88.4 21.896,75.9 10.896,89.6 C3.696,98.8 1.096,121.9 2.796,133.1 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M2.796,133.1 C1.096,122 3.696,98.8 10.996,89.6 C21.896,75.9 34.996,88.3 39.096,100.5 C41.296,107.2 48.696,139.4 42.496,143.1 C39.096,145.2 22.296,144.1 17.596,144 C9.096,143.7 4.196,142.3 2.796,133.1 L2.796,133.1 Z M13.596,84.1 C29.296,75.2 33.696,90.7 37.396,90.1 C38.796,85.6 33.296,47.4 31.996,40.3 C30.596,32.4 23.696,1.3 13.196,2.1 C7.596,6.5 10.796,33.1 10.696,41 C10.496,55.5 8.896,69.5 8.496,83.9 L7.396,89.5 L13.596,84.1 L13.596,84.1 Z M11.396,-2.84217094e-14 C5.396,11.2 8.696,31 8.396,44.1 C7.996,58.9 7.296,75.4 5.296,90 C0.096,127.1 -12.204,150.5 36.396,146.5 C52.796,145.1 46.196,124.1 43.996,112.5 C41.096,97.1 39.796,84.9 38.196,69.4 C36.496,52.7 32.996,2.6 11.396,-2.84217094e-14 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <polygon
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  fill="#989189"
                  points="13.596 84.1 8.596 87.3 8.496 83.9 7.496 89.5"
                ></polygon>
                <g
                  type="implant"
                  id="implant-incisiv-11"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="incisiv-1.2" transform="translate(392.963423, 25.000000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M21.1365774,87.9 C27.8365774,90.1 25.2365774,90.9 28.7365774,92.9 L29.0365774,89.7 C28.4365774,79.3 21.3365774,-1.4 3.93657741,3.5 C1.33657741,8.5 5.03657741,18.2 5.93657741,23.9 C9.03657741,45.1 5.33657741,90 6.13657741,92.5 C9.03657741,92.6 11.0365774,84.7 21.1365774,87.9 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M2.43657741,134.6 C4.73657741,143.1 31.4365774,145.3 34.7365774,137.3 C37.6365774,130.4 33.4365774,108.4 30.2365774,101.4 C25.1365774,90.4 15.1365774,83.2 7.23657741,96.7 C3.13657741,103.6 0.436577412,127 2.43657741,134.6 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M2.43657741,134.6 C0.436577412,127 3.13657741,103.6 7.23657741,96.7 C15.1365774,83.2 25.1365774,90.4 30.2365774,101.4 C33.4365774,108.4 37.6365774,130.4 34.7365774,137.3 C31.4365774,145.3 4.73657741,143.1 2.43657741,134.6 L2.43657741,134.6 Z M29.0365774,89.7 C28.4365774,79.3 21.3365774,-1.4 3.93657741,3.5 C1.33657741,8.5 5.03657741,18.2 5.93657741,23.9 C9.03657741,45.1 5.33657741,90 6.13657741,92.5 C9.03657741,92.6 11.0365774,84.7 21.1365774,87.8 C29.2365774,92.4 24.4365774,90.6 29.0365774,95.5 C30.6365774,92.6 30.3365774,93.1 29.0365774,89.7 L29.0365774,89.7 Z M2.73657741,2.84217094e-14 C-0.863422588,11.4 4.53657741,27.7 5.03657741,40.6 C6.43657741,76.3 -0.563422588,113.2 0.0365774117,128.5 C0.336577412,135.6 2.03657741,140.3 9.23657741,142.6 C14.4365774,144.3 26.2365774,145 31.3365774,143 C42.4365774,138.7 36.7365774,115.9 34.5365774,107.2 C31.0365774,93.4 30.6365774,79.8 29.0365774,65.7 C26.7365774,46.7 24.0365774,7.4 2.73657741,2.84217094e-14 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M29.0365774,89.7 L28.7365774,92.9 C25.2365774,90.8 27.8365774,90 21.1365774,87.9 C29.2365774,92.5 24.4365774,90.7 29.0365774,95.6 C30.6365774,92.6 30.3365774,93.1 29.0365774,89.7 Z"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-incisiv-12"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="canin-1.3" transform="translate(340.656040, 0.500000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M8.64395971,2.3 C6.44395971,11.5 8.54395971,18 9.04395971,27.2 C9.54395971,36.8 9.44395971,46.4 9.24395971,56.1 C9.04395971,63.7 6.84395971,104.6 8.44395971,108.4 C11.4439597,109.4 14.0439597,102.2 22.1439597,102.7 C28.8439597,103.1 33.7439597,110.4 35.3439597,109.4 C36.6439597,103.3 30.4439597,58.4 28.9439597,49.4 C26.5439597,35.5 22.5439597,9.2 8.64395971,2.3 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M18.6439597,104.9 C6.54395971,108.8 3.04395971,129.8 2.14395971,140.5 C1.24395971,149.8 1.64395971,152.9 9.14395971,158.4 C14.3439597,162.2 20.2439597,167.3 26.9439597,166.4 C55.8439597,162.8 38.1439597,98.7 18.6439597,104.9 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M18.6439597,104.9 C38.1439597,98.7 55.8439597,162.8 26.9439597,166.4 C20.1439597,167.2 14.3439597,162.2 9.14395971,158.4 C1.64395971,152.9 1.34395971,149.8 2.14395971,140.5 C3.14395971,129.8 6.54395971,108.8 18.6439597,104.9 L18.6439597,104.9 Z M35.3439597,109.4 C36.6439597,103.3 30.4439597,58.4 28.9439597,49.4 C26.5439597,35.5 22.5439597,9.2 8.64395971,2.2 C6.44395971,11.4 8.54395971,17.9 9.04395971,27.1 C9.54395971,36.7 9.44395971,46.3 9.24395971,56 C9.04395971,63.6 6.84395971,104.5 8.44395971,108.3 C11.4439597,109.3 14.0439597,102.1 22.1439597,102.6 C28.8439597,103.1 33.7439597,110.4 35.3439597,109.4 L35.3439597,109.4 Z M7.54395971,0 C4.04395971,11.8 7.34395971,36.1 7.14395971,50.1 C6.94395971,67.8 6.14395971,85.5 5.44395971,103.2 C4.94395971,116.6 -1.95604029,139 0.543959706,150 C5.84395971,173.5 56.3439597,179.9 41.9439597,127.5 C32.2439597,91.9 36.9439597,11.3 7.54395971,0 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-canin-13"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="premolar-1.4" transform="translate(290.497689, 31.482156)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M11.1023114,3.31784377 C3.60231144,7.01784377 6.30231144,21.4178438 6.70231144,28.1178438 C7.20231144,38.6178438 7.10231144,49.5178438 6.80231144,60.1178438 C5.30231144,114.717844 4.90231144,74.4178438 26.6023114,86.1178438 C32.6023114,89.4178438 34.8023114,96.7178438 33.6023114,74.1178438 C33.1023114,64.3178438 32.1023114,54.6178438 30.8023114,44.8178438 C29.7023114,37.3178438 25.1023114,-3.58215623 11.1023114,3.31784377 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M2.40231144,121.117844 C4.20231144,130.517844 18.4023114,139.917844 28.4023114,133.917844 C41.8023114,126.017844 41.5023114,117.817844 36.7023114,103.917844 C24.2023114,68.0178438 -2.89768856,93.8178438 2.40231144,121.117844 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M2.40231144,121.117844 C-2.89768856,93.8178438 24.3023114,68.0178438 36.7023114,104.017844 C41.5023114,117.917844 41.8023114,126.117844 28.4023114,134.017844 C18.4023114,139.917844 4.20231144,130.517844 2.40231144,121.117844 L2.40231144,121.117844 Z M11.1023114,3.31784377 C3.60231144,7.01784377 6.30231144,21.4178438 6.70231144,28.1178438 C7.20231144,38.6178438 7.10231144,49.5178438 6.80231144,60.1178438 C5.30231144,114.717844 4.90231144,74.4178438 26.6023114,86.1178438 C32.6023114,89.4178438 34.8023114,96.7178438 33.6023114,74.1178438 C33.1023114,64.3178438 32.1023114,54.6178438 30.8023114,44.8178438 C29.7023114,37.3178438 25.1023114,-3.58215623 11.1023114,3.31784377 L11.1023114,3.31784377 Z M10.2023114,0.617843775 C0.802311443,4.11784377 4.70231144,30.3178438 4.80231144,38.1178438 C5.10231144,53.1178438 4.40231144,68.3178438 3.80231144,83.4178438 C3.30231144,95.3178438 -2.09768856,112.817844 0.902311443,122.817844 C4.00231144,133.217844 17.2023114,141.317844 28.0023114,136.617844 C41.0023114,130.917844 43.4023114,119.717844 39.7023114,106.917844 C35.0023114,90.6178438 36.4023114,81.9178438 35.1023114,65.5178438 C34.3023114,54.5178438 30.3023114,-6.78215623 10.2023114,0.617843775 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <polygon
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  fill="#989189"
                  points="11.1023114 3.31784377 13.1023114 12.8178438 15.5023114 24.5178438 16.1023114 38.3178438 16.7023114 49.1178438 17.3023114 55.7178438 18.4023114 49.6178438 18.5023114 44.6178438 18.0023114 35.1178438 17.9023114 24.1178438 15.6023114 11.9178438 12.9023114 1.41784377"
                ></polygon>
                <g
                  type="implant"
                  id="implant-premolar-14"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="premolar-1.5" transform="translate(246.366118, 27.800000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M7.73388214,90 L8.03388214,92 L13.6338821,88.9 C24.5338821,84.6 26.5338821,93 30.5338821,93.3 C32.8338821,85.9 21.0338821,1.8 11.5338821,2.3 C7.63388214,6.9 10.1338821,37.9 10.1338821,45.9 C10.0338821,60.7 8.83388214,75.1 7.73388214,90 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M13.5338821,91.5 L12.5338821,92.5 L11.4338821,93.4 L10.5338821,94.4 L9.53388214,95.3 C3.73388214,102.6 0.333882141,118.9 2.83388214,127.8 C5.53388214,137.6 21.7338821,142.6 30.0338821,136.8 C41.9338821,128.5 38.9338821,121.3 35.0338821,108.5 C32.1338821,99.8 24.9338821,85.2 13.5338821,91.5 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M9.43388214,95.4 L6.63388214,92.8 L7.83388214,90 C8.93388214,75.1 10.0338821,60.7 10.2338821,45.8 C10.3338821,37.8 7.83388214,6.8 11.6338821,2.2 C21.1338821,1.8 32.9338821,85.9 30.6338821,93.2 C26.7338821,93 24.6338821,84.5 13.7338821,88.8 L9.43388214,92.5 L10.5338821,94.5 C9.63388214,92.3 9.23388214,92.6 11.4338821,93.5 C10.5338821,91.2 10.2338821,91.6 12.5338821,92.6 C11.8338821,90.6 11.5338821,90.9 13.5338821,91.6 C25.0338821,85.3 32.1338821,99.9 34.8338821,108.7 C38.8338821,121.5 41.7338821,128.7 29.8338821,137 C21.5338821,142.8 5.33388214,137.7 2.63388214,128 C0.133882141,119 3.63388214,102.7 9.43388214,95.4 L9.43388214,95.4 Z M9.73388214,0 C5.33388214,12.1 8.03388214,30.5 8.03388214,43.8 C7.93388214,58.4 7.13388214,73.3 5.73388214,87.9 C4.53388214,100.4 -1.86611786,115.2 0.533882141,127 C3.03388214,139 19.1338821,145.1 29.7338821,139.9 C43.3338821,133.1 41.0338821,120.8 37.1338821,108.6 C32.3338821,93.7 30.1338821,54.3 24.8338821,29 C22.6338821,19.6 21.2338821,1.4 9.73388214,0 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M13.6338821,88.8 L8.03388214,91.9 L7.73388214,89.9 L6.53388214,92.7 L9.33388214,95.3 L10.3338821,94.4 L11.2338821,93.4 L12.3338821,92.5 L13.3338821,91.5 C11.3338821,90.9 11.6338821,90.5 12.3338821,92.5 C10.0338821,91.5 10.3338821,91.1 11.2338821,93.4 C9.03388214,92.4 9.33388214,92.2 10.3338821,94.4 L9.23388214,92.4 L13.6338821,88.8 Z"
                  fill="#989189"
                ></path>
                <g
                  type="implant"
                  id="implant-premolar-15"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="molar-1.6" transform="translate(171.986539, 37.700000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M5.01346068,10 C-0.586539318,24.6 3.11346068,38 6.31346068,52.7 C14.9134607,92.2 -3.58653932,89.4 37.0134607,85.7 C41.0134607,85.3 43.6134607,85 47.5134607,86.3 C49.1134607,86.9 52.8134607,89.3 54.2134607,88 C56.1134607,86.3 55.9134607,47.5 55.3134607,42.5 C54.5134607,35.8 46.9134607,10.3 37.5134607,12.8 C31.0134607,14.6 41.3134607,32.7 36.7134607,53.4 C28.8134607,89 17.0134607,16.9 5.01346068,10 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M38.6134607,87.9 C19.3134607,91.9 9.81346068,82.1 4.11346068,107.9 C-0.0865393185,126.9 13.8134607,137.1 28.4134607,126.2 C34.5134607,121.6 37.4134607,130.3 46.1134607,130.6 C69.4134607,131.4 62.0134607,83 38.6134607,87.9 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M38.6134607,87.9 C62.0134607,83 69.4134607,131.4 46.2134607,130.6 C37.4134607,130.3 34.6134607,121.6 28.5134607,126.2 C13.9134607,137.1 0.0134606815,126.9 4.21346068,107.9 C9.71346068,82.1 19.3134607,91.9 38.6134607,87.9 L38.6134607,87.9 Z M5.01346068,10 C-0.586539318,24.6 3.11346068,38 6.31346068,52.7 C14.9134607,92.2 -3.58653932,89.4 37.0134607,85.7 C41.0134607,85.3 43.6134607,85 47.5134607,86.3 C49.1134607,86.9 52.8134607,89.3 54.2134607,88 C56.1134607,86.3 55.9134607,47.5 55.3134607,42.5 C54.5134607,35.8 46.9134607,10.3 37.5134607,12.8 C31.0134607,14.6 41.3134607,32.7 36.7134607,53.4 C28.8134607,89 17.0134607,16.9 5.01346068,10 L5.01346068,10 Z M31.7134607,60.8 C24.9134607,56.9 6.41346068,1.3 19.8134607,1.8 C28.9134607,2.2 43.7134607,46 31.7134607,60.8 L31.7134607,60.8 Z M17.3134607,0 C14.6134607,5.7 14.7134607,13.8 10.1134607,10.6 C2.21346068,5.2 0.413460682,15.2 0.113460682,21.1 C-1.38653932,44.2 12.6134607,69.7 5.21346068,96.1 C2.31346068,106.7 -5.68653932,130.3 16.5134607,132.1 C25.1134607,132.8 28.8134607,126.6 35.4134607,128.9 C42.5134607,131.4 48.1134607,134.9 55.7134607,129.5 C67.6134607,120.9 58.4134607,99.3 57.7134607,84.5 C56.5134607,60 62.6134607,31.3 45.4134607,14.4 C37.1134607,6.3 36.2134607,12.2 31.1134607,10.3 C26.7134607,8.8 28.5134607,2.5 17.3134607,0 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M31.7134607,60.8 C43.6134607,46 28.8134607,2.2 19.8134607,1.8 C6.41346068,1.4 25.0134607,56.9 31.7134607,60.8 Z"
                  fill="#FFFFFF"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-16"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="molar-1.7" transform="translate(96.527978, 40.500000)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M26.1720223,8.7 C26.2720223,23.5 31.7720223,34.5 31.4720223,51 C30.9720223,78 22.0720223,53.9 20.0720223,43.5 C19.1720223,38.7 19.5720223,0.8 9.87202226,15.4 C2.97202226,25.8 4.47202226,46.9 5.77202226,58.9 C9.37202226,93.7 5.47202226,83.3 22.3720223,83.5 C50.1720223,83.8 51.5720223,96.9 50.0720223,82.6 C47.4720223,59.7 49.0720223,22.5 26.1720223,8.7 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M1.97202226,110.1 C2.87202226,115.2 8.77202226,121.8 13.8720223,122.7 C22.5720223,124.2 21.2720223,116.2 31.4720223,124.2 C44.4720223,134.4 55.8720223,121.5 54.1720223,106.5 C51.9720223,87.4 40.2720223,87.8 25.0720223,85.8 C10.0720223,83.9 -0.627977743,94.9 1.97202226,110.1 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M1.97202226,110.1 C-0.627977743,94.9 10.0720223,83.9 24.9720223,85.8 C40.1720223,87.7 51.8720223,87.4 54.0720223,106.5 C55.7720223,121.5 44.3720223,134.4 31.3720223,124.2 C21.1720223,116.2 22.5720223,124.2 13.7720223,122.7 C8.77202226,121.8 2.87202226,115.2 1.97202226,110.1 L1.97202226,110.1 Z M9.87202226,15.4 C2.97202226,25.8 4.47202226,46.9 5.77202226,58.9 C9.37202226,93.7 5.47202226,83.3 22.3720223,83.5 C50.1720223,83.8 51.5720223,96.9 50.0720223,82.6 C47.5720223,59.7 49.0720223,22.5 26.2720223,8.7 C26.3720223,23.5 31.8720223,34.5 31.5720223,51 C31.0720223,78 22.1720223,53.9 20.1720223,43.5 C19.1720223,38.6 19.5720223,0.8 9.87202226,15.4 L9.87202226,15.4 Z M19.6720223,11.9 C18.3720223,8 10.8720223,6.1 17.6720223,1.9 C23.2720223,3.3 22.8720223,9.4 23.3720223,14 C23.8720223,18.7 33.5720223,49.1 28.1720223,60 C23.1720223,55.6 20.9720223,36.7 20.5720223,30.1 C20.3720223,26.7 20.3720223,14.1 19.6720223,11.9 L19.6720223,11.9 Z M15.3720223,2.84217094e-14 C9.07202226,14.4 3.77202226,17.9 2.67202226,35.3 C1.67202226,51 5.47202226,65.9 5.27202226,81.1 C5.07202226,94.4 -5.72797774,106.3 4.17202226,118.5 C8.87202226,124.4 13.2720223,124.9 20.3720223,124.2 C30.4720223,123.2 28.8720223,127.1 36.6720223,129 C52.6720223,132.9 57.6720223,116.8 55.8720223,103.9 C53.7720223,88.6 51.2720223,76.3 50.3720223,60.5 C48.9720223,38 40.8720223,6 15.3720223,2.84217094e-14 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M28.2720223,60 C33.6720223,49.1 23.8720223,18.7 23.4720223,14 C22.9720223,9.4 23.2720223,3.3 17.7720223,1.9 C10.8720223,6.1 18.4720223,8 19.7720223,11.9 C20.4720223,14.1 20.5720223,26.7 20.7720223,30.1 C21.0720223,36.8 23.2720223,55.7 28.2720223,60 Z"
                  fill="#FFFFFF"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-17"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
              <g id="molar-1.8" transform="translate(24.518130, 58.177341)">
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M2.78187017,7.42265926 C3.68187017,17.5226593 6.28187017,26.7226593 7.18187017,36.9226593 C8.18187017,48.8226593 5.78187017,59.6226593 6.38187017,69.5226593 C6.88187017,77.5226593 20.9818702,58.8226593 39.5818702,71.4226593 C41.4818702,72.7226593 45.9818702,77.9226593 46.8818702,74.1226593 C49.4818702,61.9226593 41.3818702,30.1226593 33.8818702,19.1226593 C16.5818702,-6.57734074 36.3818702,48.3226593 26.4818702,50.6226593 C23.0818702,46.4226593 21.7818702,12.1226593 2.78187017,7.42265926 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="coroana"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M21.3818702,69.0226593 C-8.81812983,74.9226593 1.78187017,109.522659 14.3818702,108.222659 C20.2818702,107.622659 20.1818702,99.5226593 30.5818702,106.322659 C46.4818702,116.722659 53.9818702,102.022659 49.2818702,87.5226593 C45.7818702,76.5226593 33.4818702,66.6226593 21.3818702,69.0226593 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  type="contur"
                  d="M21.3818702,69.0226593 C33.3818702,66.6226593 45.7818702,76.6226593 49.2818702,87.6226593 C53.9818702,102.122659 46.4818702,116.822659 30.5818702,106.422659 C20.1818702,99.6226593 20.2818702,107.722659 14.3818702,108.322659 C1.78187017,109.522659 -8.81812983,74.9226593 21.3818702,69.0226593 L21.3818702,69.0226593 Z M6.38187017,69.5226593 C6.88187017,77.5226593 20.9818702,58.8226593 39.5818702,71.4226593 C41.4818702,72.7226593 45.9818702,77.9226593 46.8818702,74.1226593 C49.4818702,61.9226593 41.3818702,30.1226593 33.8818702,19.1226593 C16.5818702,-6.57734074 36.3818702,48.3226593 26.4818702,50.6226593 C23.1818702,46.4226593 21.7818702,12.1226593 2.88187017,7.42265926 C3.78187017,17.5226593 6.38187017,26.7226593 7.28187017,36.9226593 C8.28187017,48.8226593 5.78187017,59.6226593 6.38187017,69.5226593 L6.38187017,69.5226593 Z M23.1818702,17.7226593 C26.5818702,26.1226593 27.8818702,34.9226593 26.9818702,44.0226593 C24.0818702,37.0226593 24.0818702,30.1226593 19.7818702,21.2226593 C16.4818702,14.3226593 7.98187017,6.82265926 9.58187017,1.22265926 C14.8818702,1.52265926 21.3818702,13.1226593 23.1818702,17.7226593 L23.1818702,17.7226593 Z M7.38187017,0.122659261 L7.08187017,4.22265926 L5.68187017,4.52265926 L2.78187017,4.62265926 C-2.71812983,19.4226593 12.5818702,41.0226593 1.18187017,81.1226593 C-1.61812983,91.0226593 -0.0181298251,107.822659 12.2818702,110.022659 C17.9818702,111.022659 20.5818702,105.622659 26.2818702,107.022659 C32.0818702,108.422659 34.2818702,112.922659 41.8818702,111.622659 C56.9818702,109.022659 51.4818702,90.1226593 50.0818702,80.8226593 C47.2818702,63.1226593 48.4818702,27.4226593 30.9818702,11.8226593 C26.7818702,8.12265926 23.4818702,10.8226593 19.7818702,6.92265926 C14.9818702,1.72265926 15.2818702,-0.577340739 7.38187017,0.122659261 Z"
                  id="Shape"
                  fill="#989189"
                ></path>
                <path
                  type="radacina"
                  @mousemove="hoverDinte"
                  @mouseout="hoverText = null"
                  d="M26.9818702,44.0226593 C27.8818702,34.9226593 26.5818702,26.2226593 23.1818702,17.7226593 C21.3818702,13.1226593 14.8818702,1.52265926 9.58187017,1.22265926 C7.98187017,6.82265926 16.4818702,14.3226593 19.7818702,21.2226593 C24.0818702,30.1226593 24.0818702,36.9226593 26.9818702,44.0226593 Z"
                  fill="#FFFFFF"
                ></path>
                <g
                  type="implant"
                  id="implant-molar-18"
                  class="implant absolute z1"
                  v-html="svg.implant.sus"
                ></g>
              </g>
            </g>
          </g>
        </svg>
        <span class="codes code-bottom block flex flex-between">
          <span v-for="(item, index) in coduri.bottom" :key="index">
            {{ item }}
          </span>
        </span>
      </i>
    </figure>

    <div v-if="isPunte" @click="cancelPunte" class="text-center w100">
      <button>ANULEAZĂ PUNTE</button>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { SVG } from '@svgdotjs/svg.js';
  export default {
    name: 'schita-dentara',

    data () {
      return {
        coduri: {
          top: [
            1.8,
            1.7,
            1.6,
            1.5,
            1.4,
            1.3,
            1.2,
            1.1,
            2.1,
            2.2,
            2.3,
            2.4,
            2.5,
            2.6,
            2.7,
            2.8
          ],
          bottom: [
            4.8,
            4.7,
            4.6,
            4.5,
            4.4,
            4.3,
            4.2,
            4.1,
            3.1,
            3.2,
            3.3,
            3.4,
            3.5,
            3.6,
            3.7,
            3.8
          ]
        },
        meniu: [],
        isMenu: false,
        target: undefined,
        hasTratament: false,
        cases: [
          {
            coroana: true
          },
          {
            coroana: true,
            hasTreatment: true
          },
          {
            coroana: true,
            hasPunte: true
          },
          {
            coroana: true,
            hasTreatment: true,
            hasTreatmentCoroana: true
          },
          {
            coroana: true,
            hasTreatment: true,
            hasPunte: true
          },
          {
            coroana: true,
            removed: true
          },
          {
            coroana: true,
            extractie: true
          },
          {
            radacina: true
          },
          {
            radacina: true,
            hasTreatment: true
          },
          {
            radacina: true,
            removed: true
          },
          {
            radacina: true,
            extractie: true
          },
          {
            extractie: true
          },
          {
            extractie: true,
            removed: true
          },
          {
            removed: true
          },
          {
            implant: true,
            coroana: true
          },
          {
            implant: true,
            coroana: true
          }
        ],
        actions: [
          [
            'adaugă coroană',
            'punte ceramică / Zirconiu',
            'punte acrilică provizorie',
            'punte metalo-ceramică',
            'extracție',
            'șterge dinte'
          ],
          [
            'adaugă coroană',
            'punte ceramică / Zirconiu',
            'punte acrilică provizorie',
            'punte metalo-ceramică',
            'extracție'
          ],
          ['adaugă coroană', 'extracție'],
          [
            'adaugă coroană',
            'punte ceramică / Zirconiu',
            'punte acrilică provizorie',
            'punte metalo-ceramică',
            'extracție'
          ],
          ['adaugă coroană', 'extracție'],
          ['implant', 'adaugă dinte'],
          ['adaugă coroană', 'implant'],
          ['tratament endodontic', 'extracție', 'șterge dinte'],
          ['tratament endodontic', 'extracție'],
          ['implant', 'adaugă dinte'],
          ['tratament endodontic', 'implant'],
          ['implant'],
          ['implant', 'adaugă dinte'],
          ['implant', 'adaugă dinte'],
          ['adaugă dinte'],
          [
            'adaugă coroană',
            'punte ceramică / Zirconiu',
            'punte acrilică provizorie',
            'punte metalo-ceramică'
          ]
        ],
        dinte: undefined,
        hoverText: '',
        svgElm: undefined,
        schita: undefined,
        points: {
          top: {
            'molar-1.8': 44,
            'molar-1.7': 116,
            'molar-1.6': 198,
            'premolar-1.5': 260,
            'premolar-1.4': 306,
            'canin-1.3': 358,
            'incisiv-1.2': 404,
            'incisiv-1.1': 450,
            'incisiv-2.1': 508,
            'incisiv-2.2': 548,
            'canin-2.3': 600,
            'premolar-2.4': 650,
            'premolar-2.5': 696,
            'molar-2.6': 760,
            'molar-2.7': 840,
            'molar-2.8': 910
          },
          bottom: {
            'molar-4.8': 44,
            'molar-4.7': 116,
            'molar-4.6': 192,
            'premolar-4.5': 258,
            'premolar-4.4': 310,
            'canin-4.3': 364,
            'incisiv-4.2': 416,
            'incisiv-4.1': 456,
            'incisiv-3.1': 494,
            'incisiv-3.2': 536,
            'canin-3.3': 588,
            'premolar-3.4': 642,
            'premolar-3.5': 696,
            'molar-3.6': 760,
            'molar-3.7': 838,
            'molar-3.8': 910
          }
        },
        dinteActiv: undefined,
        isPunte: false,
        punteSide: undefined,
        firstClickX: undefined,
        fill: undefined,
        /* inregistreaza dintii de plecare pentru punti */
        punti: [],
        denumirePunte: undefined
      };
    },

    computed: {
      ...mapState({
        pacient: 'pacient',
        tratamente: 'tratamente',
        svg: 'svg',
        defaults: 'defaults',
        dantura: 'dantura'
      })
    },

    created () {
      this.width = window.innerWidth;
    },

    mounted () {
      this.svgElm = SVG('#svg-schita');
      this.schita = document
        .getElementById('svg-schita')
        .getBoundingClientRect();
    },

    methods: {
      hoverDinte (ev) {
        if (ev.currentTarget.parentNode.getAttribute('type') !== 'extractie') {
          this.hoverText = ev.currentTarget.getAttribute('tratament');
          this.$refs.hover.style.setProperty(
            '--top-hover',
            `${ev.pageY - 30}px`
          );
          this.$refs.hover.style.setProperty(
            '--left-hover',
            `${ev.pageX - 40}px`
          );
        }
      },

      clickDinte (ev) {
        this.target = ev.target;
        const className = this.target.parentElement.parentElement.getAttribute(
          'class'
        );
        this.punteSide =
          className && className.includes('top') ? 'top' : 'bottom';

        if (!this.isPunte) {
          this.dinteActiv = this.target.parentElement.getAttribute('id');
          this.firstClickX = ev.x;
        } else {
          const direction = this.firstClickX < ev.x ? 'right' : 'left';
          const start = this.dantura[this.punteSide].indexOf(this.dinteActiv);
          const end = this.dantura[this.punteSide].indexOf(
            this.target.parentElement.getAttribute('id')
          );
          const punte =
            direction === 'right'
              ? this.dantura[this.punteSide].slice(start, end + 1)
              : this.dantura[this.punteSide].slice(end, start + 1);
          if (start !== end) {
            const clbk = () => {
              punte.forEach((val, i) => {
                const rectWidth =
                  this.points[this.punteSide][punte[i + 1]] -
                  this.points[this.punteSide][punte[i]];
                this.makePunte(
                  this.points[this.punteSide][val],
                  rectWidth,
                  i + 1 === punte.length
                );
              });
              this.$refs[`punte-${this.punteSide}`].style.display = 'block';
              this.isPunte = false;
              this.firstClickX = false;
              this.dinteActiv = false;
              this.punteSide = undefined;
            };
            this.target.nodeName !== 'svg' &&
              this.$store.dispatch('save_punte', {
                clbk,
                side: this.punteSide,
                start,
                end,
                direction,
                fill: this.fill,
                denumire: this.denumirePunte
              });
          }
        }

        if (this.target.getAttribute('type') !== 'dinti-box' && !this.isPunte) {
          const parent = ev.target.parentElement;
          let index;
          this.dinte = parent.getAttribute('id');

          const CASE = {
            coroana: this.checkType('coroana'),
            radacina: this.checkType('radacina'),
            extractie: parent.getAttribute('type') === 'extractie',
            hasTreatment:
              this.pacient.history &&
              this.pacient.history.some(
                (obj) => obj.dinte === this.dinte.replace('-', ' ')
              ),
            hasTreatmentCoroana:
              this.pacient.history &&
              this.pacient.history.some(
                (obj) =>
                  obj.dinte === this.dinte.replace('-', ' ') && +obj.mapID < 2
              ),
            removed:
              this.pacient.dintiStersi &&
              this.pacient.dintiStersi.some(
                (name) => name === this.dinte.replace(' ', '-')
              ),
            implant:
              this.pacient.history &&
              this.pacient.history.some(
                (obj) =>
                  obj.dinte === this.dinte.replace('-', ' ') && obj.mapID === 4
              ),
            hasPunte: this.punti.indexOf(this.dinte) > -1
          };

          /* find the scenario */
          this.cases.forEach((obj, i) => {
            const condition = Object.keys(obj).every(
              (key) => obj[key] === CASE[key]
            );
            condition && (index = i.toString());
          });

          /* populate the menu */
          index && (this.meniu = this.actions[parseInt(index)].slice());

          /* position the menu if svg elm is clicked */
          if (this.target.nodeName === 'path') {
            this.$refs.menu.style.setProperty('--top', `${ev.pageY}px`);
            this.$refs.menu.style.setProperty('--left', `${ev.pageX}px`);
            !this.isPunte && (this.isMenu = index);
          }
        }
      },

      makePunte (value, rectWidth, last, fill) {
        const y = this.punteSide === 'top' ? 180 : 216;
        this.svgElm
          .circle(16)
          .move(value, y)
          .fill(fill || this.fill)
          .attr('class', `punte-${this.punteSide}`);
        !last &&
          this.svgElm
            .rect(rectWidth, 7)
            .move(value + 4, y + 4)
            .fill(fill || this.fill)
            .attr('class', `punte-${this.punteSide}`);
        /* sterge atributul temporar ID folosit doar cand se anuleaza puntea */
        const temp = document.getElementById('temp-start-point');
        temp &&
          last &&
          document.getElementById('temp-start-point').removeAttribute('id');
      },

      cancelPunte () {
        this.isPunte = false;
        this.firstClickX = false;
        this.dinteActiv = false;
        this.$refs[`punte-${this.punteSide}`].style.display = 'block';
        const elmToHide = document.getElementsByClassName(
          `punte-${this.punteSide === 'top' ? 'bottom' : 'top'}`
        );
        elmToHide.forEach((elm) => {
          elm.style.display = 'block';
        });
        const temp = document.getElementById('temp-start-point');
        temp && temp.parentNode.removeChild(temp);
      },

      checkType (type) {
        return this.target.getAttribute('type') === type;
      },

      setAction (item) {
        this.dinte &&
          this.$store.commit('SET_KEY_VALUE', {
            key: 'modalData',
            sub: 'dinte',
            value: this.dinte.replace('-', ' ')
          });
        switch (item) {
          case 'punte ceramică / Zirconiu':
            this.denumirePunte = 'punte ceramică / Zirconiu';
            this.creazaPunte(6.1);
            break;
          case 'punte acrilică provizorie':
            this.denumirePunte = 'punte acrilică provizorie';
            this.creazaPunte(6.4);
            break;
          case 'punte metalo-ceramică':
            this.denumirePunte = 'punte metalo-ceramică';
            this.creazaPunte(6.5);
            break;
          // case 'crează punte':
          //   this.creazaPunte()
          //   break
          case 'șterge dinte':
            this.stergeDinte();
            break;
          case 'adaugă dinte':
            const clbk = () => {
              this.target.parentElement.style.opacity = '1';
            };
            this.$store.dispatch('sterge_dinte', { dinte: this.dinte, clbk });
            break;
          default:
            this.$store.commit('SET_KEY_VALUE', {
              key: 'filterTratamente',
              value: item
            });
            this.openModal();
        }
        this.closeMenu();
      },

      closeMenu () {
        this.isMenu = false;
      },

      openModal () {
        const obj = {
          val: true,
          name: 'addTratamentModal',
          setValues: {
            type: 'tratament',
            id: new Date().getTime()
          }
        };
        this.$store.commit('SET_KEY_VALUE', {
          key: 'disableDintiDD',
          value: true
        });
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isUpdateModal',
          value: false
        });
        this.$store.commit('SET_MODAL', obj);
      },

      creazaPunte (id) {
        this.isPunte = true;
        /* hide punti aferente side-ului selectat */
        const elmToHide = document.getElementsByClassName(
          `punte-${this.punteSide === 'top' ? 'bottom' : 'top'}`
        );
        elmToHide.forEach((elm) => {
          elm.style.display = 'none';
        });
        /* hide side */
        this.$refs[`punte-${this.punteSide}`].style.display = 'none';
        const y = this.punteSide === 'top' ? 180 : 216;
        /* pick the color from dinte origine and apply to punte
        document.getElementById(this.dinteActiv).children.forEach(elm => {
          elm.getAttribute('type') === 'coroana' && (this.fill = elm.style.fill)
        }) */
        switch (id) {
          /* apply fill to punte */
          case 6.1:
            this.fill = '#34a186';
            break;
          case 6.4:
            this.fill = '#a9e8e6';
            break;
          case 6.5:
            this.fill = '#FFB400';
            break;
        }
        /* make the starting point dot */
        this.svgElm
          .circle(16)
          .move(this.points[this.punteSide][this.dinteActiv], y)
          .fill(this.fill)
          .attr('class', `punte-${this.punteSide}`)
          .attr('id', 'temp-start-point');
      },

      stergeDinte () {
        this.$store.commit('SET_KEY_VALUE', {
          key: 'dinteElm',
          value: this.target
        });
        this.$store.commit('SET_KEY_VALUE', {
          key: 'numeDinte',
          value: this.dinte
        });
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isUpdateModal',
          value: false
        });
        this.$store.commit('SET_KEY_VALUE', {
          key: 'confirmationMsg',
          value: 'Sunteți sigur că doriți să ștergeți acest dinte?'
        });
        this.$store.commit('SET_KEY_VALUE', {
          key: 'confirmationSubject',
          value: 'dinte'
        });
        const obj = {
          val: true,
          name: 'confirmationModal',
          setValues: {}
        };
        this.$store.commit('SET_MODAL', obj);
      }
    },

    watch: {
      pacient: {
        handler: function (newVal, old) {
          const arr = (newVal.history && newVal.history.slice()) || [];

          arr.reverse().forEach((obj) => {
            if (obj.mapID) {
              const elm =
                obj.dinte &&
                document.getElementById(obj.dinte.replace(' ', '-'));
              let dinte = elm.getAttribute('id');
              /* aplica mapare coroana doar daca nu este implant */
              if (!obj.implant && elm) {
                switch (obj.mapID) {
                  /* apply coroana color mapping only if is not Implant */
                  case 1.1:
                    /* make the color of punte from dinte origine coroana */
                    // setPunteFill('#34a186', dinte)
                    styleCoroana(elm, '#34a186', 1.1, this.$store);
                    break;
                  case 1.2:
                    /* make the color of punte from dinte origine coroana */
                    // setPunteFill('#4fcfb1', dinte)
                    styleCoroana(elm, '#4fcfb1', 1.2, this.$store);
                    break;
                  case 1.3:
                    /* make the color of punte from dinte origine coroana */
                    // setPunteFill('#4fcfb1', dinte)
                    styleCoroana(elm, '#4fcfb1', 1.3, this.$store);
                    break;
                  case 1.4:
                    /* make the color of punte from dinte origine coroana */
                    // setPunteFill('#a9e8e6', dinte)
                    styleCoroana(elm, '#a9e8e6', 1.4, this.$store);
                    break;
                  case 1.5:
                    /* make the color of punte from dinte origine coroana */
                    // setPunteFill('#FFB400', dinte)
                    styleCoroana(elm, '#FFB400', 1.5, this.$store);
                    break;
                }
              } else {
                styleCoroana(elm, '#FFF', obj.mapID, this.$store);
              }
              switch (obj.mapID) {
                /* extractie */
                case 3:
                  elm.lastChild.style.display !== 'block' &&
                    (elm.style.opacity = this.defaults.opacity);
                  elm.setAttribute('type', 'extractie');
                  break;
                /* implant */
                case 4:
                  Array.from(elm.childNodes).forEach((path, i) => {
                    const type =
                      path.getAttribute('type') === 'radacina' ||
                      path.getAttribute('type') === 'contur';
                    type && elm.removeChild(path);
                    if (path.getAttribute('type') === 'coroana') {
                      path.style.stroke = '#989189';
                      path.style.strokeWidth = '2px';
                    }
                    elm.lastChild.style.display = 'block';
                    elm.style.opacity = '1';
                  });
                  break;
                case 5:
                  if (!elm.getAttribute('implant')) {
                    styleCoroana(elm, '#248091', 5, this.$store, 'radacina');
                  }
                  break;
                case 5.1:
                  if (!elm.getAttribute('implant')) {
                    styleCoroana(elm, '#248091', 5.1, this.$store, 'radacina');
                  }
                  break;
                case 5.2:
                  if (!elm.getAttribute('implant')) {
                    styleCoroana(elm, '#aa8091', 5.2, this.$store, 'radacina');
                  }
                  break;
                case 5.3:
                  if (!elm.getAttribute('implant')) {
                    styleCoroana(elm, '#aa8091', 5.3, this.$store, 'radacina');
                  }
                  break;
              }
            }
          });

          newVal.dintiStersi &&
            newVal.dintiStersi.forEach((dinte) => {
              const elm = document.getElementById(dinte);
              if (elm && newVal) {
                let isImplant;
                newVal.history.forEach((obj) => {
                  obj.dinte === dinte.replace('-', ' ') &&
                    obj.mapID === 4 &&
                    (isImplant = true);
                });
                !isImplant && (elm.style.opacity = '0');
              }
            });

          /* map punte */
          newVal.history &&
            newVal.history.forEach(
              ({ denumire, side, start, end, direction, fill }) => {
                if (denumire && denumire.includes('punte')) {
                  /* make last dinte same color as punte
              const element = document.getElementById(this.dantura[side][end])
              Array.from(element.childNodes).forEach((path, i) => {
                path.getAttribute('type') === 'coroana' && (path.style.fill = fill)
              })
              */
                  this.punteSide = side;
                  // this.punti.push(this.dantura[this.punteSide][start])
                  /* make the list of dinti from punti */
                  if (direction === 'right') {
                    let s = start;
                    let e = end;
                    while (s <= e) {
                      this.punti.push(this.dantura[this.punteSide][s]);
                      s++;
                    }
                  } else {
                    let s = start;
                    let e = end;
                    while (e <= s) {
                      this.punti.push(this.dantura[this.punteSide][s]);
                      e++;
                    }
                  }
                  const punte =
                    direction === 'right'
                      ? this.dantura[this.punteSide].slice(start, end + 1)
                      : this.dantura[this.punteSide].slice(end, start + 1);
                  punte.forEach((val, i) => {
                    const rectWidth =
                      this.points[this.punteSide][punte[i + 1]] -
                      this.points[this.punteSide][punte[i]];
                    this.makePunte(
                      this.points[this.punteSide][val],
                      rectWidth,
                      i + 1 === punte.length,
                      fill
                    );
                  });
                }
              }
            );

          function styleCoroana (elm, color, id, store, type = 'coroana') {
            const tratName = store.state.tratamente
              .filter((obj) => obj.id === id)
              .map((obj) => obj.name)[0];
            elm.children.forEach((e) => {
              e.getAttribute('type') === type && (e.style.fill = color);
              e.getAttribute('type') === type &&
                e.setAttribute('tratament', tratName);
            });
          }

          /* make the color of punte from dinte origine coroana */
          function setPunteFill (color, dinte) {
            newVal.history.forEach((obj) => {
              if (
                obj.denumire &&
                obj.denumire.includes('punte') &&
                obj.dinteStart === dinte
              ) {
                obj.fill = color;
              }
            });
          }
        },
        deep: true
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  @import "../assets/styles/mixin";
  .schita-dentara {
    --top: 0;
    --left: 0;
    --top-hover: 0;
    --left-hover: 0;
    padding-top: 24px;

    .codes {
      font-size: 1.8rem;
      font-weight: bold;
      font-style: normal;
      opacity: 0.4;
    }
    .code-top {
      padding: 0 30px;
      transform: translateY(40px);
      span {
        display: inline-block;
      }
      span:nth-child(2) {
        transform: translateX(2px);
      }
      span:nth-child(3) {
        transform: translateX(16px);
      }
      span:nth-child(4) {
        transform: translateX(42px);
      }
      span:nth-child(5) {
        transform: translateX(32px);
      }
      span:nth-child(6) {
        transform: translateX(22px);
      }
      span:nth-child(7) {
        transform: translateX(14px);
      }
      span:nth-child(8) {
        transform: translateX(9px);
      }
      span:nth-child(9) {
        transform: translateX(12px);
      }
      span:nth-child(10) {
        transform: translateX(12px);
      }
      span:nth-child(11) {
        transform: translateX(-3px);
      }
      span:nth-child(12) {
        transform: translateX(-14px);
      }
      span:nth-child(13) {
        transform: translateX(-28px);
      }
      span:nth-child(14) {
        transform: translateX(-24px);
      }
      span:nth-child(15) {
        transform: translateX(-8px);
      }
    }
    .code-bottom {
      padding: 0 12px;
      transform: translateY(12px);
      span:nth-child(2) {
        transform: translateX(20px);
      }
      span:nth-child(3) {
        transform: translateX(36px);
      }
      span:nth-child(4) {
        transform: translateX(44px);
      }
      span:nth-child(5) {
        transform: translateX(32px);
      }
      span:nth-child(6) {
        transform: translateX(26px);
      }
      span:nth-child(7) {
        transform: translateX(22px);
      }
      span:nth-child(8) {
        transform: translateX(9px);
      }
      span:nth-child(9) {
        transform: translateX(-12px);
      }
      span:nth-child(10) {
        transform: translateX(-24px);
      }
      span:nth-child(11) {
        transform: translateX(-35px);
      }
      span:nth-child(12) {
        transform: translateX(-34px);
      }
      span:nth-child(13) {
        transform: translateX(-44px);
      }
      span:nth-child(14) {
        transform: translateX(-30px);
      }
      span:nth-child(15) {
        transform: translateX(-18px);
      }
    }

    .oglinda-dentara {
      i {
        max-width: 950px;
      }
      svg {
        width: 100%;
      }
    }

    .dinti-bottom {
      transform: translateY(50px);
    }

    .dinti-bottom,
    .dinti-top {
      g {
        cursor: pointer;
      }
    }

    p.hover {
      top: var(--top-hover);
      left: var(--left-hover);
      cursor: default;
      background-color: white !important;
      border: 1px solid @medium-grey;
      border-radius: 3px;
      padding: 4px 8px;
      font-size: 1.5rem;
      z-index: 99999;
    }

    .start-punte {
    }

    nav {
      font-size: 1.6rem;
      z-index: 10;
      top: var(--top);
      left: var(--left);
      ul {
        background-color: white;
        border-radius: 8px;
        border: 1px solid @medium-grey;
      }
      li {
        padding: 8px 16px;
        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        &:hover {
          background-color: @light-grey;
        }
      }
      i {
        transform: scale(1.1);
        right: -10px;
        top: -10px;
        transition: transform 0.1s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .implant {
      display: none;
    }

    figure + div {
      margin-top: -60px;
      button {
        border: none;
        border-radius: 4px;
        padding: 8px 16px;
        background-color: @dark-grey;
        color: white;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.1s ease-in-out;
        &:hover {
          background-color: darken(@dark-grey, 20%, relative);
        }
      }
    }

    #implant-molar-38 {
      transform: translate(17px, 35px);
    }

    #implant-molar-37 {
      transform: translate(17px, 39px);
    }

    #implant-molar-36 {
      transform: translate(21px, 44px);
    }

    #implant-premolar-35 {
      transform: translate(6px, 44px);
    }

    #implant-premolar-34 {
      transform: translate(5px, 42px);
    }

    #implant-canin-33 {
      transform: translate(7px, 54px);
    }

    #implant-incisiv-32 {
      transform: translate(0, 49px);
    }

    #implant-incisiv-31 {
      transform: translate(0, 47px);
    }

    #implant-incisiv-41 {
      transform: translate(0, 47px);
    }

    #implant-incisiv-42 {
      transform: translate(0, 49px);
    }

    #implant-canin-43 {
      transform: translate(5px, 55px);
    }

    #implant-premolar-44 {
      transform: translate(5px, 44px);
    }

    #implant-premolar-45 {
      transform: translate(6px, 46px);
    }

    #implant-molar-46 {
      transform: translate(25px, 43px);
    }

    #implant-molar-47 {
      transform: translate(21px, 39px);
    }

    #implant-molar-48 {
      transform: translate(31px, 35px);
    }

    #implant-molar-18 {
      transform: translate(10px, 3px);
    }

    #implant-molar-17 {
      transform: translate(12px, 17px);
    }

    #implant-molar-16 {
      transform: translate(16px, 18px);
    }

    #implant-premolar-15 {
      transform: translate(4px, 23px);
    }

    #implant-premolar-14 {
      transform: translate(5px, 21px);
    }

    #implant-canin-13 {
      transform: translate(6px, 38px);
    }

    #implant-incisiv-12 {
      transform: translate(3px, 24px);
    }

    #implant-incisiv-11 {
      transform: translate(6px, 19px);
    }

    #implant-incisiv-21 {
      transform: translate(9px, 19px);
    }

    #implant-incisiv-22 {
      transform: translate(6px, 22px);
    }

    #implant-canin-23 {
      transform: translate(7px, 39px);
    }

    #implant-premolar-24 {
      transform: translate(7px, 22px);
    }

    #implant-premolar-25 {
      transform: translate(6px, 25px);
    }

    #implant-molar-26 {
      transform: translate(14px, 19px);
    }

    #implant-molar-27 {
      transform: translate(13px, 17px);
    }

    #implant-molar-28 {
      transform: translate(12px, 3px);
    }

    .crosshair {
      svg,
      svg g {
        cursor: crosshair !important;
      }
    }
  }
</style>
